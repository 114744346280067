import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import AreaExposicao from '../AreaExposicao';
import AreaInstitucional from '../AreaInstitucional';
import { ContactIcons, scrollTo, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';
import { logo, urlWebsite } from '../../config';
import { Container, SecondaryContainer, ContainerWhite, GreyContainer, Capa, Sponsors, Photo } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';
import SpeakersGrid from '../../components/SpeakersGrid';

import { Pattern } from '../../styles/ThemeComponents';
import ShowDiv from '../../components/ShowOnScroll';
import VideoBackground from '../../components/VideoBackground';
import TextPopper from '../../components/TextPopper';
import Scroller from '../../components/Scroller';

import Publicas from './modules/publicas';
import Programacao from '../Program/';
import Vimeo from '../../components/Vimeo';
import GaleriaFotos from '../GaleriaFotos';
import ContagemRegressiva from '../../components/ContagemRegressiva';
import Logo from '../../components/Logo';
import Speakers from '../Speakers';
import { usePages } from '../../hooks/Pages';

import SponsorsList from '../Sponsors';
import { useLanguage } from '../../hooks/Language';
import api from '../../services/api';
import { toPTBr } from '../../utils/date';
import Youtube from '../../components/Youtube';
import { Apoio, ApoioGrid } from '../Sponsors/styles';
import Program from '../Program';
import Palestrantes from '../Palestrantes';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IEventData {
  title : string;
  date_text : string;
  start_at : string;
  image : string;
}

const SignIn: React.FC = () => {

  const [eventData, setEventData] = useState<IEventData>({} as IEventData);
  const { translate, language} = useLanguage();

 
  const loadEvent = async () => {
    const response = await api.get('/events-list');

    if(response.data && response.data[0]){
      setEventData(response.data[0]);
    }

  }

useEffect( () => {

   loadEvent();

},[])
  


  return (
    <>
      <Capa style={{padding: '0px'}}>


    <div className="insight"><img src="/apoio/insight.png"/></div>

    <h2 className="capaTitle">
  O maior evento phygital de inovação do Rio Grande do Sul
  <br/>
  <h2>10 e 11 de Setembro de 2021</h2>
  <p style={{fontSize:'24px'}}>Hubs em Pelotas, Rio Grande e Lajeado</p>
</h2>


    <div className="hand computerOnly"><img src="/apoio/predio.png"/></div>
    <div className="cataventos notmobile"><img src="/apoio/estatua.png"/></div>
    <Link to="#" onClick={(e) => scrollTo(e,'programa')} className="vr computerOnly" style={{display:'none'}}><img src="/apoio/vr.png"/></Link>

    <Link to="#" onClick={(e) => scrollTo(e,'programa')} className="drone computerOnly"><img src="/apoio/drone.png"/></Link>
 
   

    
    <div className="hand mobileOnly"><img src="/apoio/predio.png"/></div>
      </Capa>

      <Capa style={{minHeight: '0px', justifyContent:'center', alignItems: 'center'}} id="programa">

<div className="p50" style={{ margin: '50px auto', padding: '20px 40px'}}>
  <h2 className="titleDescription" >
  VAMOS JUNTOS AMPLIAR E FORTALECER  O ECOSSISTEMA DE INOVAÇÃO DO SUL DO BRASIL!
  </h2>
  <p style={{fontSize:'18px'}}>
  Vem aí INSIGHT SUL, o evento presencial e digital que vai expandir as conexões e a atmosfera de inovação entre os ecossistemas da região Sul e do Vales do Taquari e do Rio Pardo.
<br/><br/>
Vamos conectar pessoas, ideias e regiões através de experiências divididas em Hubs de Inovação em Pelotas, Rio Grande e Lajeado.
<br/><br/>
Participe conosco da criação de ideias de valor que geram resultados. Cidades inteligentes, conhecimento, cases de sucesso para pequenos e médios empreendedores, conexões com empresas, setor público, universidades e toda a comunidade. Os novos modelos de negócios, as soft skills, o intraempreendedorismo, as tendências e a disrupção vão ser os destaques das nossas palestras. 
 
 </p>
  <a target="_BLANK" href="https://digital.sebraers.com.br/?s=insight+sul" style={{textDecoration:'none'}} ><h2 style={{ marginTop: '40px', color: '#fff', fontSize: '30px',background: 'rgb(0,0,100)', padding: '20px' }}>
  Participe! Venha para o INSIGHT SUL!
  </h2>
  </a>

</div>
<div className="player p50" >
        <Youtube id="XLO5W8rklpw"/>
        </div>
      </Capa>
 

      <Capa style={{minHeight:'0px', flexDirection:"column", alignItems: 'center', justifyContent: 'flex-start', padding: '50px 0px'}} id="ingressos">

      <h2 className="titleDescription" >
  INGRESSOS
  </h2>

        <ApoioGrid size="GG">
            <a href="https://digital.sebraers.com.br/?post_type=tribe_events&p=38568" target="_BLANK"><Apoio background="/apoio/botao_ingresso_passaporte.jpg"/></a>
            <a href="https://digital.sebraers.com.br/?post_type=tribe_events&p=38112" target="_BLANK"><Apoio background="/apoio/botao_house_ingresso_pelotas.jpg"/></a>
            <a href="https://digital.sebraers.com.br/?post_type=tribe_events&p=38577" target="_BLANK"><Apoio background="/apoio/botao_ingresso_hub_pelotas.jpg"/></a>
            <a href="https://digital.sebraers.com.br/produtos/inovacao/eventos/hub-rio-grande-oceantec-insight-sul/" target="_BLANK"><Apoio background="/apoio/botao_ingresso_hub_riogrande.jpg"/></a>
            <a href="https://digital.sebraers.com.br/?post_type=tribe_events&p=38594" target="_BLANK"><Apoio background="/apoio/botao_ingresso_hub_vales.jpg"/></a>
        </ApoioGrid>
      </Capa>

   



      <div className="vr" style={{width: '40%', margin: '20px auto'}}><img style={{maxWidth:'100%'}} src="/apoio/vr.png"/></div>
      <Capa style={{minHeight: '0px', paddingBottom: '100px', paddingTop:'0px', justifyContent:'center', alignItems: 'flex-start', flexWrap:'wrap'}} id="speakers">


  <Palestrantes/>


      </Capa>
   
     

    <Capa style={{minHeight: '0px', padding: '0px'}}>
      <div className="mobileOnly"><img style={{width:'100%'}} src="/apoio/estatua.png"/></div>
     
      </Capa>
  


 
    </>
  );
};
export default SignIn;
