import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import { background } from '../../config';
import { ThemeDefinitions } from '../../styles/ThemeComponents';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const blur = keyframes`
from {
  opacity: 0;
  filter: blur(20px) ;
}
to{
  opacity: 1;
  filter: blur(0px) ;
}
`;


const rotateSmoth = keyframes`
from {

  transform: rotateX(0deg) ;
}
to{
  transform: rotateX(20deg) ;
}
`;

interface IBackground {
  background?: string;
}

export const Container = styled.div<IBackground>`
  min-height: 30vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  color: #555;
  padding: 20px 10px;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  transition: 0.4s all;

 

  @media (max-width: 750px) {
    padding: 30px 20px;
  }

  .sponsors {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 30px 0;
    flex-wrap: wrap;

    > div {
      width: 300px;
      max-width: 300px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      > h2 {
        font-size: 16px;

        color: rgb(50, 50, 50);
      }
      > img {
        width: 100%;
        margin: 20px;
      }

      > img.logoGerenciamento {
        margin-top: 50px;
        width: 70%;
      }
    }

    @media (max-width: 750px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  > .blocoLogo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;

    .logoEvento {
      width: 100%;
      max-width: 500px;
      height: auto;
      transform: translateX(-5%);
    }

    .logoEventoMini {
      width: 100px;
      margin-top: 50px;
    }
  }

  .bloco {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;

    > a {
      margin: 10px;
    }

    @media (max-width: 750px) {
      flex-direction: column;
    }

    > a {
      > button {
        width: 200px;
        padding: 20px;
        text-align: center;
        background: rgb(0, 102, 178);
        color: #fff;
        transition: background 0.5s;
        border: 0;
        border-radius: 8px;

        &:hover {
          background: rgb(0, 102, 178);
        }
      }
    }
  }

  .separator {
    width: 100%;
    max-width: 200px;
    height: 2px;
    background: rgb(0, 102, 178);
    margin: 20px 0;
  }

  .borderedBottom {
    border: 2px solid #fff;
    padding: 10px;
    color: #fff;
    background: none;
    opacity: 0.8;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }
  }

  .logoEvento {
    width: 400px;
    max-width: 80%;
    z-index: 200;
  }

  .palestrantes {
    max-width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 750px) {
      margin: 20px 0;
    }

    > .palestranteDiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .palestrante {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        height: 100px;
        margin: 10px;
        border: 2px solid rgb(0, 102, 178);
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
        }
      }

      > h2 {
        font-size: 16px;
        font-weight: bold;
        color: rgb(0, 102, 178);
      }

      > p {
        font-size: 14px;

        color: rgb(0, 102, 178);
      }
    }
  }

  .paragraph {
    max-width: 500px;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 102, 178);
    }
  }
`;

export const ContainerWhite = styled(Container)`
  min-height: 90vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  color: #fff;
  padding: 100px 5%;
  width: 100%;
  max-width: 100%;
  margin: auto;
  z-index:200;

  button{
    position: relative;
    z-index: 200;
  }

  @media (max-width: 1200px) {
    padding: 30px 20px;
    flex-direction: column;
  }

  > .p50 {
    z-index: 1;
    width: 50%;
    min-width: 50%;
    margin: 30px 0;

    @media (max-width: 1200px) {
      width: 100%;
      min-width: 100%;
    }

    > h2 {
      font-size: 1.5em;
    }

    > p {
      text-align: justify;
    }
  }

  .p600 {
    max-width: 800px;
    min-width: auto;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 102, 178);
    }

    >h2{
      font-size: 22px;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 18px;

      color: rgb(0,102,178);
      margin-top: 20px;

    }

    > a {
      z-index: 2;
       } button {
         margin: 10px;
      width: 200px;
      padding: 20px;
      text-align: center;
      background: rgb(0, 102, 178);
      color: #fff;
      transition: background 0.5s;
      border: 0;
      border-radius: 8px;

      &:hover {
        background: rgb(0, 102, 178);
      }
    }
  }
  }

  .ProgramGroup {
    display: flex;

    @media (max-width: 1200px) {

      >button {
        max-width: 32%;
        padding: 10px;
        margin: 5px;
      }
    }

  }
`;

interface IEvent {
  active: boolean;
}

export const Evento = styled.div<IEvent>`
  background: #fff;
  color: #333;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  width: 100%;
  padding: 30px 20px;
  z-index: 200;
  margin: 10px 0;
  position: relative;
  animation: ${fadeFromLeft} 0.5s;
  border-radius: 10px;
  > button {
    max-width: 10px;
    width: 10px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    height: 10px;
    color: rgb(0, 102, 178) !important;
    background: none !important;
    border: 0px;
    transition: 0.2s all;
    z-index: 202;

    &:hover {
      color: rgb(0, 100, 200);
    }
  }

  > div.evento-horario {
  }

  .evento-title {
    font-size: 20px;
    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }

  .separadorPrograma {
    margin-top: 10px;
    color: #333;
  }

  .eventoExtra {
    display: flex;
    flex-direction: column;
    padding: 20px;
    animation: ${fadeFromLeft} 0.5s;
    > ul {
      list-style: none;
      margin-left: -25px;
      > li {
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
  > h2 {
    font-size: 18px;
  }
`;

export const PhotoMini = styled.div<IBackground>`
  width: 70px;
  min-width: 70px;
  margin: 5px;
  border-radius: 50%;
  height: 70px;
  background: url(${props => props.background}) no-repeat;
  background-size: cover;
  position: relative;
  border: 1px solid rgb(0, 102, 178);

  .arco1 {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 30%;
    height: 30%;
    background: rgb(251, 178, 22);
    border-radius: 50%;
  }

  .arco2 {
    position: absolute;
    bottom: -0px;
    right: -10%;
    width: 20%;
    height: 20%;
    background: none;
    border: 1px solid rgb(246, 146, 30);
    border-radius: 50%;
  }
`;



export const Ballom = styled.div`
display: flex;
align-items: center;
justify-content: center !important;
background: rgba(0, 0, 100,0.7);
border-radius: 20px;
padding : 5px;
font-size: 10px;
font-weight: bold;
color: #fff;
width: 100px;
text-align: center;
`;

export const DateDiv = styled.div`
color: #fff;
font-size: 14px;
padding: 10px;
font-weight: bold;
width: 120px;

@media (max-width: 1200px) {
  font-size: 14px;
}

`;

export const ImgDiv = styled.div``;

export const ContentDiv = styled.div`
padding: 10px;

>h2{
  font-weight: bold;
  font-size: 24px;
  color: #fff;
  @media (max-width: 1200px) {
  font-size: 18px;
}
}

>p{

  font-size: 14px;
  color: #fff;
}

`;

export const Line = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
width: 100%;
padding: 20px 40px;
border-bottom: 1px solid #fff;

@media (max-width: 1200px) {
  padding: 20px 10px;
    }

>div{
  display: flex;

justify-content: flex-start;
}

`;

export const ContainerButton = styled.div`
display: flex;
justify-content: center;
width: 100%;
align-items: center;
padding: 20px;

@media (max-width: 1200px) {
    flex-wrap: wrap;
  }

.buttonProgram{
  width: 100%;
  max-width: 190px;
  padding: 10px;

  @media (max-width: 1200px) {
    max-width: 100px;
    padding: 5px;
  }

  >img {
    width: 100%;
    cursor: pointer;
    box-shadow: 0px 0px 2px rgba(255,255,255,0.3);
    transition: 0.5s all;
    border-radius:20px;
    border: 3px solid #fff;

    &:hover{
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);
      transform: translateY(-10px);
    }
  }
}


`;

export const HeadLine = styled.div`
padding: 20px 40px;
border: 5px solid #ec6607;
background: #ec6607;
color: #fff;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
width: 100%;

>h2{
  font-size: 32px;

  @media (max-width: 1200px) {
  font-size: 24px;
}


}
>p{
 font-size: 16px;
  @media (max-width: 1200px) {
  font-size: 14px !important;
} 
}
`;