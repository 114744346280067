import React from 'react';

import { uuid } from 'uuidv4';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';
import createModule from '../../components/SearchComponent/modules/Create';
import updateModule from '../../components/SearchComponent/modules/Update';
import deleteModule from '../../components/SearchComponent/modules/Delete';
import { Container, Header, Body } from './styles';
import api from '../../services/api';
import { useSocket } from '../../hooks/Socket';
import { Button } from '../../components/Button/styles';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

export const UpdateCoursesModule: React.FC = () => {

  const {socket} = useSocket();
  const updateCourses = () => {
    socket.emit('SendUpdateCourses');
  }

  return <Button style={{margin:'5px auto'}} onClick={() => updateCourses()}>Atualizar Cursos para participantes</Button>
}

const Course: React.FC = () => {
  const endpoint = '/Courses';

  const formSchema = {
    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
      defaultValue: '',
    },
    lessons: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
{data.id})
{' '}
{data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
{data.id})
{data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </>
      ),
      storageType: 'json',
      endpoint: `${endpoint}/feedSelect/lessons`,
      name: 'lessons',
      label: 'Aulas',
    },
  };

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };

  const key2 = uuid();

  const schema2: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    lessons: { ref: 'lessons', column: 'lessons', label: 'Aulas', show: false },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const config2: ISearchComponent = {
    idKey: key2,
    title: 'Cursos',
    endpoint: '/courses',
    schema: schema2,
    autoStart: true,
    headModules: [
      () =>
        createModule({
          title: 'Cursos',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        updateModule({
          lineData,
          title: 'Cursos',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        deleteModule({
          lineData,
          title: 'Cadastro',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };



  return (
    <Container>
      <UpdateCoursesModule/>
      <SearchComponent {...config2} />
    </Container>
  );
};

export default Course;
