const program = { title : 'Hub Pelotas Parque Tecnológico',
    date: '11/09 sábado',
    description : `Av. Domingos de Almeida, 1785 – Pelotas/RS<br/>
    Em meio ao ecossistema de tecnologia, o Hub Pelotas Parque Tecnológico estimulará a criação de novas soluções que atendam o presente e o futuro, integrando a comunidade acadêmica aos setores público e privado que circulam pelo espaço. O espaço contará com a transmissão da programação do Insight House e atividades in loco como palestras e oficinas.`,
    image : '/botao/botao_programacao_hub_pelotas.png',
    day: '11',
    city: 'Pelotas',
    activity : [
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '08:00 às 08:50',
            title : 'Boas Vindas',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '09:00 às 09:50',
            title : 'Transmissão Insight House',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '10:00 às 10:50',
            title : 'Painel Experiências Gastronomicas com o Chef Ricardo Dorneles',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '11:00 às 11:50',
            title : 'Painel UFPEL - Pesquisa, Desenvolvimento e Empreendedorismo de Base Tecnológica',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '12:00 às 13:50',
            title : 'Intervalo',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '14:00 às 14:50',
            title : 'Transmissão Insight House',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '15:00 às 15:50',
            title : 'Painel Educação do Futuro sob a perspectiva dos jovens com Guilhermina Abreu e João Lucas',
            subtitle: 'Mediação: Roselaine Moraes',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '16:00 às 16:50',
            title : 'Painel Empreendedorismo Feminino e Inovação com Cacá Anjos (influencer, podcaster e You tuber), Josiane Feijó (CEO da Ingnis) e Andreia Nascimento (Analista de Inteligência de Mercado do SebraeRS)',
            subtitle: 'Mediação: Danúbia Espíndola - Pró Reitora de Inovação e Tecnologia da Informação da Furg',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '17:00 às 17:50',
            title : 'Transmissão Insight House ',
            subtitle: '',
            description : '',
            photos : []
        },
        
    ]

}

export default program;