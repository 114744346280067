const program = { title : 'Hub Rio Grande',
    date: '10/09 sexta-feira',
    city: 'Rio Grande',
    description : `AV. Rhengantiz 203 – Rio Grande/RS<br/>
    Em meio ao ecossistema de tecnologia, o Hub Rio Grande o espaço contará com programações de inovação e cultura em Rio Grande, além de oficinas, palestras e debates. Será um ambiente dedicado a novas experiências para pensar o futuro das cidades e do empreendedorismo. Contará ainda com a transmissão da programação do Insight House para integrar todo o evento na mesma sintonia de atividades e inspirações. 
    `,
    day: '10',
    image : '/botao/botao_programacao_hub_riogrande.png',
    activity : [
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '08:00 às 08:50',
            title : 'Credenciamento',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '09:00 às 09:50',
            title : 'Transmissão Insight House',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '10:00 às 10:50',
            title : 'Transmissão Insight House',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '11:00 às 11:50',
            title : 'Prof Dr Márcio Machado, professor visitante da FURG, executivo do iTec - Embrapii - Inovação e Convergência de Tecnologias como Oportunidade de Negócios',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '12:00 às 13:50',
            title : 'INTERVALO',
            subtitle: '',
            description : '',
            photos : []
        },        
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '14:00 às 14:50',
            title : 'Transmissão Insight House',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '15:00 às 15:50',
            title : 'Painel O papel dos Parques Tecnológicos e Incubadoras de Empresas na Nova Economia - Parques UNISC, UNIVATES, UFPEL e FURG - Mediação: Gustavo Moreira ',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '16:00 às 16:50',
            title : 'Painel Indústria 4.0 - Prof Dr Paulo Drews do Centro de Ciências Computacionais da FURG e Pesquisador do iTEC/FURG e Lucas Elizalde - Diretor Yara Brasil da Planta Rio Grande',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '17:00',
            title : 'Transmissão Insight House',
            subtitle: '',
            description : '',
            photos : []
        },
      
        {
            room_name : 'Atrações',
            room_color: 'rgb(0, 0, 100)',
            date_text : '',
            title : `Oficinas Maker com Empresas Juniores da FURG<br/><br/>
            Mostra de Startups<br/>
            AlgaSul Biotecnologia de Microalgas<br/>
TerraMares<br/>
Anota ai<br/>
Freecash<br/>
AuRos Robotics
            `,
            subtitle: '',
            description : '',
            photos : []
        },
    ]

}

export default program;