const program = { 
    title : 'Insight House',
    date: '11/09 sábado',
    description : `Av. Dom Joaquim, 1087 – Pelotas/RS<br/>
    A “casa” do Insight Sul receberá palestrantes e convidados para estimular os debates e aprendizados que acontecerão nos demais espaços. O espaço anfitrião receberá a programação transmitida para os hubs com experiências, negócios, conhecimento, networking e interação. O Insight House contará com o espaço Like a Boss para startups, painéis gastronômicos exclusivos e cases do Histórias que Inspiram. 
    `,
    image : '/botao/botao_programacao_house_pelotas.png',
    day: '11',
    city: 'Pelotas',
    activity : [
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '08:00',
            title : 'Credenciamento',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '09:00 às 09:50',
            title : 'Palestra Camila Farani - Vencendo Tubarões',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '10:00 às 10:50',
            title : 'Painel Histórias que Inspiram - AGRONEGÓCIO - Agregação de Valor em produtos Tradicionais',
            subtitle: 'Painelistas: Ariana Maia - Inovamente, Mariana Rosa e Paulo Almeida - Canto Queijaria e Adriano Fiss Bosenbecker - Produtor Rural de Pêssego e Coordenador de Núcleo de Morro Redondo - Mediação: Amanda Paim',
            description : '',
            photos : []
        }, 
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '11:00 às 11:50',
            title : ' Rafael Martins - CEO e Cofundador do Clube do Share',
            subtitle: 'A Comunicação do Futuro e como isso impacta no seu negócio',
            description : '',
            photos : []
        },  
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '12:00 às 13:50',
            title : 'INTERVALO',
            subtitle: '',
            description : '',
            photos : []
        },  
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '14:00 - 14:50',
            title : 'Geraldo Rufino - Fundador da JR Diesel',
            subtitle: 'Criatividade e Inovação',
            description : '',
            photos : []
        },      
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '15:00 às 15:50',
            title : 'Lisiane Lemos',
            subtitle: 'Especialista em tecnologia - Tecnologia para Negócios',
            description : '',
            photos : []
        },         
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '16:00 às 16:50',
            title : 'Painel Agro Sicredi - Inovação e Tecnologia 4.0:',
            subtitle: 'o campo e o produtor cada vez mais conectados',
            description : '',
            photos : []
        },     
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '17:00 às 17:50',
            title : 'Arthur Igreja - Investidor anjo e cocriador da AAA',
            subtitle: 'Cultura da Inovação e Inovação Disruptiva',
            description : '',
            photos : []
        },  
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '18:00',
            title : 'Encerramento',
            subtitle: '',
            description : '',
            photos : []
        }, 

        {
            room_name : 'Palco Like a Boss', room_color: '#89398f',
            date_text : '10:00 às 10:30',
            title : 'Talk livre com Camila Farani',
            subtitle: '',
            description : '',
            photos : []
        }, 
        {
            room_name : 'Palco Like a Boss', room_color: '#89398f',
            date_text : '10:30 às 11:50',
            title : 'Round Final - com 6 startups',
            subtitle: '',
            description : '',
            photos : []
        },      
        {
            room_name : 'Palco Like a Boss', room_color: '#89398f',
            date_text : '12:00 às 14:50',
            title : 'Intervalo',
            subtitle: '',
            description : '',
            photos : []
        }, 
        {
            room_name : 'Palco Like a Boss', room_color: '#89398f',
            date_text : '15:00 às 15:30',
            title : 'Talk',
            subtitle: '',
            description : '',
            photos : []
        },      
        {
            room_name : 'Palco Like a Boss', room_color: '#89398f',
            date_text : '15:30 às 16:50',
            title : 'Resultado Like a Boss',
            subtitle: '',
            description : '',
            photos : []
        },    
        {
            room_name : 'Atrações', room_color: 'rgb(0, 0, 100)',
            date_text : '',
            title : `
            Mostra de 10 Startups<br/>
            Connectbe contabilidade pró-ativa<br/>
vouconstruir.net<br/>
Nuinset<br/>
Partamon<br/>
Dream Tech - Casas Conectadas<br/>
Green Next<br/>
AkiDelivery<br/>
Criação .cc<br/>
Anota ai<br/>
Captur Investimentos<br/><br/>


            Talks exclusivos`,
            subtitle: '',
            description : '',
            photos : []
        },


    ]

}

export default program;