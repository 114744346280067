import React, { useState, useEffect } from 'react';

import { FiUsers, FiUserPlus, FiCreditCard, FiUserCheck, FiMenu, FiBarChart } from 'react-icons/fi';
import { FaArrowLeft, FaBox, FaBoxes, FaBuilding, FaCalendar, FaCalendarCheck, FaCertificate, FaChartBar, FaCreditCard, FaGift, FaImage, FaMicrophone, FaStar, FaTicketAlt, FaUser, FaVoteYea} from 'react-icons/fa';


import CounterCard from './core/components/CounterCard';
import CounterCardRepeat from './core/components/CounterCardSocket';
import api from '../../services/api';
import {
  Container,

  CardContainer,
RoomReport,RoomReportDiv,
  Sidebar
} from './styles';

import { useAuth } from '../../hooks/Auth';
import Lessons from './pages/Lessons';
import Sale from './pages/Sales';
import Expositor from './pages/Expositors';
import Speaker from './pages/Speakers';
import Students from './pages/Students';
import Program from './pages/Programs';
import Courses from './pages/Courses';
import ExpositorVote from './pages/ExpositorVotes';
import SponsorsAdmin from './pages/Sponsors';
import Reporter from './pages/Reporter';
import { Link, useParams } from 'react-router-dom';
import { authTitle } from '../../config';
import ProductsAdmin from './pages/Products';
import Certificate from './pages/Certificates';
import Events from './pages/Events';
import ExpositorsList from './pages/ExpositorList';
import { id } from 'date-fns/locale';
import ImageGallery from './pages/ImageGallery';
interface IReport {
  id : number,
  client_id : number,
  room_id : number,
  name : string,
}

interface IOnline {
  title : string;
  room_id : number;
  count : number;
}

interface IParams {
  tab : string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/students';


  const {tab = 'student'} = useParams<IParams>();
  const { token, user } = useAuth();

  const [online, setOnline] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showOnlineUsers, setShowOnlineUsers] = useState(false);
  const [report, setReport] = useState<Array<IReport>>([]);
  const [search, setSearch] = useState(tab);
  const [rooms, setRooms] = useState<Array<IOnline>>([]);

  const [sidebarActive, setSidebarActive] = useState( window.innerWidth <= 800  ? false : true);

let validSearch: Record<string, any> = {
    
    student: { icon : () => <FaUser/>, title : 'Cadastros', render : () => <Students />},

    
  };

  if(user.profile === 1){
    validSearch = {...validSearch,
      events : { icon : () => <FaCalendarCheck/>, title: 'Eventos' , render : () => <Events/>},
      expositorslist : { icon : () => <FaGift/>, title: 'Cortesias' , render : () => <ExpositorsList/>},
      imagegallery : { icon : () => <FaImage/>, title: 'Lista de imagens' , render : () => <ImageGallery/>},
      reporter : { icon : () => <FaChartBar/>, title : 'Relatórios', render: () => <Reporter/>, closeSidebar : true},
      sale: {icon : () => <FaCreditCard/>, title : 'Vendas', render : () => <Sale /> },
      speaker : { icon : () => <FaMicrophone/>, title : 'Palestrantes' , render : () => <Speaker />},
      expositor: { icon : () => <FaBuilding/>, title : 'Expositores' , render : () => <Expositor />},
      course: { icon : () => <FaBoxes/>,  title : 'Cursos', render : () => <Courses />},
      lesson: { icon : () => <FaBox/>, title : 'Palestras' , render : () => <Lessons />} ,
      vote: { icon : () => <FaVoteYea/>, title : 'Votos em expositores', render : () => <ExpositorVote />},
      program : { icon : () => <FaCalendar/>, title : 'Programação', render : () => <Program/>},
      sponsor : { icon : () => <FaStar/>, title: 'Patrocinadores' , render : () => <SponsorsAdmin/>},
      product : { icon : () => <FaTicketAlt/>, title: 'Produtos' , render : () => <ProductsAdmin/>},
      certificate : { icon : () => <FaCertificate/>, title: 'Certificados' , render : () => <Certificate/>}}
  }

  const renderValidSearch= () => {
    const items : Array<JSX.Element> = [];
   Object.keys(validSearch).map( key => {
     const {title, icon} = validSearch[key];
      items.push(<Link to={`/manager/${key}`}> {icon()} <p>{title}</p></Link>);

    })
    return items;

  }


  const [sidebarContent,setSidebarContent] = useState<JSX.Element>(   <>
   
    {renderValidSearch()}
  </>);







  useEffect(() => {

    const load = async () => {

      const response = await api.get('/report/onlinePlayersRooms');

      if(response && response.data && response.data.counter){
        setRooms(response.data.counter);
      }




    }
    load();


  }, []);

  useEffect(() => {

    if(  window.innerWidth <= 800  ){
      setSidebarActive(false);
    }

  },[tab])

  useEffect(() => {
    if(tab){
      setSearch(tab);
     
      if(validSearch?.[tab]?.closeSidebar){
        setSidebarActive(false);
      }
    }
    

  
  },[tab])



  if (loading) {
    return <Container>Você foi removido</Container>;
  }

  return (<>
   
    <Container>
       <Sidebar active={sidebarActive}>
<div className="menu" onClick={() => setSidebarActive(!sidebarActive)}><FiMenu  size="30"/></div>
    <img src="/assets/logo.png" style={{width: '90%', maxWidth:'150px', margin: '10px auto'}}/>
    <div style={{background:'rgb(0,250,50)', padding:'5px 10px', borderRadius:'5px', margin: '10px auto', color: 'rgb(0,100,0)'}}>
    {user.name}
  </div>
<div style={{background:'rgb(0,250,50)', padding:'5px 10px', borderRadius:'5px', margin: '10px auto', color: 'rgb(0,100,0)'}}>
  {authTitle}
  </div>

     
      {sidebarContent}

    </Sidebar>
    

<div style={{width: '100%'}}>  <CardContainer>
 

<Link style={{height: '46px', cursor: 'pointer'}} to="/dashboard">
   <FaArrowLeft  size="20"/>
   <p>
     Palestras
   </p>
 </Link>

 <Link style={{height: '46px', cursor: 'pointer'}} to="/manager/reporter">
   <FiBarChart  size="20"/>
   <p>
     Relatórios
   </p>
 </Link>
             <CounterCardRepeat
          
   endpoint="/report/onlinePlayers"
   title="Usuários Online"
   Icon={FiUserPlus}

 />
 <CounterCard
   endpoint="/report/users"
   title="Usuários Cadastrados"
   Icon={FiUsers}
   link="/manager/student" 
 />
  {user.profile === 1 && <>
 <CounterCard
   endpoint="/report/sales_paid"
   title="Vendas Finalizadas"
   Icon={FiCreditCard}
   link="/manager/sale"
 />
 <CounterCard
   endpoint="/report/sales_waiting"
   title="Vendas Aguardando"
   Icon={FaCreditCard}
   link="/manager/sale"
 /> </>}



</CardContainer>
    

      {validSearch[search] ? validSearch[search].render() : <></>}
      </div>

    </Container></>
  );
};

export default Dashboard;
