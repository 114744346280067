import React from 'react';
import { Menu } from '../../../components/Menu';
import { Wrapper, Content } from './styles';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
const AuthLayout: React.FC = ({ children }) => {
  const {menu} = usePages();
  return (
    <Wrapper>
         <Menu menuList={menu} fixedMenu={false} />
      <Content>{children}</Content>
      <FooterLayout/>
    </Wrapper>
  );
};

export default AuthLayout;
