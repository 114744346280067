import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import AreaExposicao from '../AreaExposicao';
import AreaInstitucional from '../AreaInstitucional';
import { ContactIcons, scrollTo, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';
import { logo, urlWebsite } from '../../config';
import { Container, SecondaryContainer, ContainerWhite, GreyContainer, Capa, Sponsors, Photo } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';
import SpeakersGrid from '../../components/SpeakersGrid';

import { Pattern } from '../../styles/ThemeComponents';
import ShowDiv from '../../components/ShowOnScroll';
import VideoBackground from '../../components/VideoBackground';
import TextPopper from '../../components/TextPopper';
import Scroller from '../../components/Scroller';

import Publicas from './modules/publicas';
import Programacao from '../Program/';
import Vimeo from '../../components/Vimeo';
import GaleriaFotos from '../GaleriaFotos';
import ContagemRegressiva from '../../components/ContagemRegressiva';
import Logo from '../../components/Logo';
import Speakers from '../Speakers';
import { usePages } from '../../hooks/Pages';

import SponsorsList from '../Sponsors';
import { useLanguage } from '../../hooks/Language';
import api from '../../services/api';
import { toPTBr } from '../../utils/date';
import Youtube from '../../components/Youtube';
import { Apoio, ApoioGrid } from '../Sponsors/styles';
import Program from '../Program';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IEventData {
  title : string;
  date_text : string;
  start_at : string;
  image : string;
}

const SignIn: React.FC = () => {

  const [eventData, setEventData] = useState<IEventData>({} as IEventData);
  const { translate, language} = useLanguage();

 
  const loadEvent = async () => {
    const response = await api.get('/events-list');

    if(response.data && response.data[0]){
      setEventData(response.data[0]);
    }

  }

useEffect( () => {

   loadEvent();

},[])
  


  return (
    <>
  
   <Program/>


    </>
  );
};
export default SignIn;
