import styled, { css } from 'styled-components';
import { backgrounds } from 'polished';
import { white } from 'color-name';
import { ThemeDefinitions } from '../../styles/ThemeComponents';

interface ISpeaker {
  background: string;
  size ?: string;
}

export const Container = styled.div`

width: 100%;


.vjs-error-display,.vjs-modal-dialog-content{
      display: none;
    }

    .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before, .vjs-icon-fullscreen-enter:before {
    content: "\f108";
    font-family: 'VideoJS';
}

.vjs-big-play-button{
  top: 50% !important;
  left: 50%  !important;
  transform: translateX(-50%)  !important;
}

.vjs-icon-placeholder::before {
  display: flex;
  align-items: center;
  justify-content: center;
}


`;
