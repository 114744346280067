const program = { 
    title : 'PASSAPORTE INSIGHT DIGITAL',
    date: '10 e 11 de Setembro',
    description : `Acompanhe a transmissão online do maior evento phygital de inovação do Rio Grande do Sul, ao vivo, pela plataforma https://insight.sebraers.com.br/.

    O evento será transmitido de 4 lugares diferentes, sendo eles Insight House, Hub Pelotas Parque Tecnológico, Hub Rio Grande e Hub dos Vales (Lajeado), e o participante digital tem acesso a todo conteúdo!
    `,
    image : '/botao/botao_programação_passaporte.png',
    day: '10',
    city: 'Pelotas',
    activity : [
        {
            room_name : '',
            room_color: '#ec6607',
            date_text : '',
            title : `Todas as palestras +<br/>
            <br/>
            Like a Boss<br/>
            <br/>
            Insight Gastronômico<br/>
            <br/>
            Oficinas Maker Virtuais<br/>
            <br/>
            Mostra Virtual de Startup`,
            subtitle: '',
            description : '',
            photos : []
        },

    ]

}

export default program;