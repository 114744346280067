import React, { useState, useEffect } from 'react';

import { IconType } from 'react-icons/lib';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Container, ContainerWhite, Evento } from './styles';

import { getSelectedSpeakers } from '../Program/program';
import SpeakerGrid from '../../components/SpeakersGrid';
import api from '../../services/api';

import { Apoio, ApoioGrid } from '../Sponsors/styles';
import { useParams } from 'react-router';
import { Capa } from '../Home/styles';

interface ProgramData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IParam{
  speaker : string;
}

const Palestrantes: React.FC = () => {

  const [currentSpeaker,setCurrentSpeaker] = useState('');

  const {speaker} = useParams<IParam>();

  useEffect(() => {

    if(speaker){
      setCurrentSpeaker(speaker);
      window.scrollTo(0,0);
    }

  },[
speaker
  ])

  const allSpeakers = {
    dado_schneider : { image: '/apoio/palestrantes/dado_schneider.png', descriptionImage : '/apoio/palestrantes/Bio_dado_schneider.png' },
    luciano_potter : { image: '/apoio/palestrantes/potter.png', descriptionImage : '/apoio/palestrantes/Bio_potter.png' },
    camila_farani : { image: '/apoio/palestrantes/camila_farani.png', descriptionImage : '/apoio/palestrantes/Bio_camila_farani.png' },
    pedro_englert : { image: '/apoio/palestrantes/pedro_englert.png', descriptionImage : '/apoio/palestrantes/Bio_Pedro_Englert.png' },
    jose_gallo : { image: '/apoio/palestrantes/Jose_Gallo2.png', descriptionImage : '/apoio/palestrantes/Bio_Jose_Gallo.png' },
    tito_gusmao : { image: '/apoio/palestrantes/Tito_gusmao.png', descriptionImage : '/apoio/palestrantes/Bio_Tito_Gusmao.png' },
    daniel_randon : { image: '/apoio/palestrantes/Daniel_Randon.png', descriptionImage : '/apoio/palestrantes/Bio_Daniel_Randon.png' },
    rafael_martins : { image: '/apoio/palestrantes/Rafael_Martins.png', descriptionImage : '/apoio/palestrantes/Bio_Rafael_Martins.png' },
    geraldo_rufino : { image: '/apoio/palestrantes/Geraldo_Rufino.png', descriptionImage : '/apoio/palestrantes/Bio_Geraldo_Rufino.png' },
    lisiane_lemos : { image: '/apoio/palestrantes/Lisiane_Lemos.png', descriptionImage : '/apoio/palestrantes/Bio_Lisiane_Lemos.png' },
    arthur_igreja : { image: '/apoio/palestrantes/Arthur_Igreja.png', descriptionImage : '/apoio/palestrantes/Bio_Arthur_Igreja.png' },
  }

  const palestrantes0 = {
    camila_farani : { image: '/apoio/palestrantes/camila_farani.png', descriptionImage : '/apoio/palestrantes/Bio_camila_farani.png' },
    jose_gallo : { image: '/apoio/palestrantes/Jose_Gallo2.png', descriptionImage : '/apoio/palestrantes/Bio_Jose_Gallo.png' },
    
   
    pedro_englert : { image: '/apoio/palestrantes/pedro_englert.png', descriptionImage : '/apoio/palestrantes/Bio_Pedro_Englert.png' },

    daniel_randon : { image: '/apoio/palestrantes/Daniel_Randon.png', descriptionImage : '/apoio/palestrantes/Bio_Daniel_Randon.png' },
   
  }

  const palestrantes = {
    tito_gusmao : { image: '/apoio/palestrantes/Tito_gusmao.png', descriptionImage : '/apoio/palestrantes/Bio_Tito_Gusmao.png' },
    geraldo_rufino : { image: '/apoio/palestrantes/Geraldo_Rufino.png', descriptionImage : '/apoio/palestrantes/Bio_Geraldo_Rufino.png' },
    lisiane_lemos : { image: '/apoio/palestrantes/Lisiane_Lemos.png', descriptionImage : '/apoio/palestrantes/Bio_Lisiane_Lemos.png' },
    dado_schneider : { image: '/apoio/palestrantes/dado_schneider.png', descriptionImage : '/apoio/palestrantes/Bio_dado_schneider.png' },
    
   
   
   
  }
  const palestrantes2 = {
 
    rafael_martins : { image: '/apoio/palestrantes/Rafael_Martins.png', descriptionImage : '/apoio/palestrantes/Bio_Rafael_Martins.png' },
  
  arthur_igreja : { image: '/apoio/palestrantes/Arthur_Igreja.png', descriptionImage : '/apoio/palestrantes/Bio_Arthur_Igreja.png' },
  luciano_potter : { image: '/apoio/palestrantes/potter.png', descriptionImage : '/apoio/palestrantes/Bio_potter.png' },  
}
  return <>
<Capa style={{minHeight: '0px', paddingBottom: '100px', paddingTop:'0px', justifyContent:'center', alignItems: 'flex-start', flexWrap:'wrap', maxWidth:'1200px'}}>
  {allSpeakers[currentSpeaker] && <img src={allSpeakers[currentSpeaker].descriptionImage} style={{width: '100%'}}/>}
  </Capa>
  
  <Capa style={{minHeight: '0px', paddingBottom: '100px', paddingTop:'0px', justifyContent:'center', alignItems: 'flex-start', flexWrap:'wrap', maxWidth:'1200px'}} id="speakers">
  <h2 className="titleDescription" style={{width: '100%', textAlign: 'center', }} >
       SPEAKERS CONFIRMADOS
  </h2>
  <ApoioGrid size="GG">
    {
      Object.keys(palestrantes0).map(key => <a href={`/palestrantes/${key}`}><Apoio background={palestrantes0[key].image}/></a>)
    }


</ApoioGrid>

  <ApoioGrid size="GG">
    {
      Object.keys(palestrantes).map(key => <a href={`/palestrantes/${key}`}><Apoio background={palestrantes[key].image}/></a>)
    }


</ApoioGrid>

<ApoioGrid size="GG">
    {
      Object.keys(palestrantes2).map(key => <a href={`/palestrantes/${key}`}><Apoio background={palestrantes2[key].image}/></a>)
    }


</ApoioGrid>
</Capa>
  
 
  </>;
};
export default Palestrantes;
