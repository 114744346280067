import React from 'react';
import { FormHandles } from '@unform/core';
import { uuid } from 'uuidv4';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';
import createModule from '../../components/SearchComponent/modules/Create';
import updateModule from '../../components/SearchComponent/modules/Update';
import deleteModule from '../../components/SearchComponent/modules/Delete';
import { Container, Header, Body } from './styles';
import api from '../../services/api';
import { sanitizeData } from '../../components/FormComponent';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const callback = (string, form: React.RefObject<FormHandles>) => {
  const installments = form?.current?.getFieldValue('installment') || 1;

  const discount =
    sanitizeData.currencyInput(form?.current?.getFieldValue('discount')) || 0;

  const total =
    sanitizeData.currencyInput(form?.current?.getFieldValue('total')) || 0;

  const installmentValue = parseInt(
    ((total - discount) / installments).toString(),
    10,
  );

  form?.current?.setFieldValue('installment_value', installmentValue);
};

const Sale: React.FC = () => {
  const endpoint = '/sales';

  const formSchema = {
    client_id: {
      alias: 'student',
      model: 'selectApi',
      name: 'client_id',
      label: 'Cliente',
      endpoint,
    },
    product_id: {
      alias: 'product',
      model: 'selectApi',
      name: 'product_id',
      label: 'Produto',
      defaultValue: 1,
      endpoint,
    },
    payment_status_id: {
      alias: 'payment_status',
      model: 'selectApi',
      name: 'payment_status_id',
      label: 'Status',
      defaultValue: 2,
      endpoint,
    },
    payment_method_id: {
      alias: 'payment_method',
      model: 'selectApi',
      name: 'payment_method_id',
      label: 'Forma de Pagamento',
      defaultValue: 1,
      endpoint,
    },
    total: {
      model: 'currencyInput',
      name: 'total',
      label: 'Total',
      defaultValue: '0',
      callback,
    },
    discount: {
      model: 'currencyInput',
      name: 'discount',
      label: 'Desconto',
      defaultValue: '0',
      callback,
    },
    installment: {
      model: 'selectSimple',
      name: 'installment',
      label: 'Nº de Parcelas',
      defaultValue: '1',
      options: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
        { label: '11', value: 11 },
        { label: '12', value: 12 },
      ],
      callback,
    },
    installment_value: {
      model: 'currencyInput',
      name: 'installment_value',
      label: 'Valor da parcela',
      defaultValue: '0',
      readOnly: true,
    },
    courses: {
      model: 'JsonInput',
      endpoint: `${endpoint}/feedSelect/courses`,
      name: 'courses',
      label: 'Cursos',
    },
  };

  const formValidation: Record<string, ValidationSchema> = {
    student_id: {
      name: 'client_id',
      type: 'string',
      label: 'Cliente',
      rules: [{ type: 'required' }],
    },
    product_id: {
      name: 'product_id',
      type: 'string',
      label: 'Produto',
      rules: [{ type: 'required' }],
    },
    payment_status_id: {
      name: 'payment_status_id',
      type: 'string',
      label: 'Status',
      rules: [{ type: 'required' }],
    },
    payment_method_id: {
      name: 'payment_method_id',
      type: 'string',
      label: 'Forma de Pagamento',
      rules: [{ type: 'required' }],
    },
  };

  const key2 = uuid();

  const schema2: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'Protocolo' },
    sale_hash: {
      ref: 'sale_hash',
      column: 'sale_hash',
      label: 'Hash',
      show: false,
    },
    installment_value: {
      ref: 'installment_value',
      column: 'installment_value',
      label: 'Valor da Parcela',
      mask: 'currency',
    },
    installment: {
      ref: 'installment',
      column: 'installment',
      label: 'Nº de Parcelas',
    },
    total: { ref: 'total', column: 'total', label: 'Total', mask: 'currency' },
    payment_status: {
      ref: 'payment_status_id',
      column: 'payment_status_id_response',
      includeColumn: 'title',
      label: 'Status',
    },
    payment_method: {
      ref: 'payment_method_id',
      column: 'payment_method_id_response',
      includeColumn: 'title',
      label: 'Forma de Pagamento',
    },
    product: {
      ref: 'product_id',
      column: 'product_id_response',
      includeColumn: 'title',
      label: 'Produto',
    },
    student: {
      ref: 'client_id',
      column: 'client_id_response',
      includeColumn: 'name',
      label: 'Cliente',
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const config2: ISearchComponent = {
    idKey: 'a2',
    title: 'Vendas',
    endpoint: '/sales',
    schema: schema2,
    autoStart: true,
    headModules: [
      () =>
        createModule({
          title: 'Venda',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        updateModule({
          lineData,
          title: 'Cadastro',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        deleteModule({
          lineData,
          title: 'Cadastro',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config2} />
    </Container>
  );
};

export default Sale;
