import styled from 'styled-components';


export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  padding-top: 0px;
  background: none;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  min-height: auto;
  z-index: -1;
`;

export const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 30px 10px;
  background: #fff;
  color: rgb(0, 100, 150);
  min-height: 100px;
  z-index: 200;
  position: relative;
  bottom: 0px;
  left: 0px;

  

  > p {
    position: relative;
    z-index: 200;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  >div, >h2, > p{
    max-width: 1200px;
    margin: auto;
  }

  >h2{
    margin-top: 30px;
    margin-bottom: 10px;
  }
`;

export const SponsorGrid = styled.div<IBackground>`

width: 100%;
max-width: 1200px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: center;
margin: auto;
`;

interface IBackground {
  background ?: string;
}




export const ApoioGrid = styled.div<IBackground>`

width: 100%;
max-width: 1200px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: center;
margin: auto;
`;

interface IBackground {
  background ?: string;
}


export const Apoio = styled.div<IBackground>`

background: url(${props => props.background}) no-repeat center;
background-size: contain;

width: 100px;
height: 66px;
margin: 5px;

@media (max-width: 1200px) {
  width: 100px;
height: 66px;
margin: 5px;
      }
`;

export const Apoio2 = styled.div<IBackground>`

background: url(${props => props.background}) no-repeat center;
background-size: contain;

width: 75px;
height: 50px;
margin: 5px;

@media (max-width: 1200px) {
  width: 75px;
height: 50px;
margin: 5px;
      }
`;

export const Sponsor = styled.div<IBackground>`

background: url(${props => props.background}) no-repeat center;
background-size: contain;

width: 150px;
height: 100px;
margin: 5px;

@media (max-width: 1200px) {
  width: 150px;
height: 100px;
      }
`;