import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaAddressCard,
} from 'react-icons/fa';

import { MdError, MdDateRange } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../Input';
import Button from '../Button';
import Loader from '../Loader';
import MaskedInput from '../MaskedInput';
import { ResponseMessage } from '../ResponseMessage';
import getValidationErrors from '../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../SelectSimple';
import Whatsapp from '../WhatsAppCompartilhar';

import api from '../../services/api';
import { authTitle, recaptchaKey } from '../../config';
import getCep from '../../services/cep';
import { useLanguage } from '../../hooks/Language';
import CheckboxInput from '../CheckboxInput';

interface IRecoverFormData {
  name: string;
  email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
}

interface ICaptcha {
  getValue(): string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const {translate} = useLanguage();

  const states = [
    { uf: 'AC', label: 'Acre (AC)', value: 1 },
    { uf: 'AL', label: 'Alagoas (AL)', value: 2 },
    { uf: 'AP', label: 'Amapá (AP)', value: 3 },
    { uf: 'AM', label: 'Amazonas (AM)', value: 4 },
    { uf: 'BA', label: 'Bahia (BA)', value: 5 },
    { uf: 'CE', label: 'Ceará (CE)', value: 6 },
    { uf: 'DF', label: 'Distrito Federal (DF)', value: 7 },
    { uf: 'ES', label: 'Espírito Santo (ES)', value: 8 },
    { uf: 'GO', label: 'Goiás (GO)', value: 9 },
    { uf: 'MA', label: 'Maranhão (MA)', value: 10 },
    { uf: 'MT', label: 'Mato Grosso (MT)', value: 11 },
    { uf: 'MS', label: 'Mato Grosso do Sul (MS)', value: 12 },
    { uf: 'MG', label: 'Minas Gerais (MG)', value: 13 },
    { uf: 'PA', label: 'Pará (PA)', value: 14 },
    { uf: 'PB', label: 'Paraíba (PB)', value: 15 },
    { uf: 'PR', label: 'Paraná (PR)', value: 16 },
    { uf: 'PE', label: 'Pernambuco (PE)', value: 17 },
    { uf: 'PI', label: 'Piauí (PI)', value: 18 },
    { uf: 'RJ', label: 'Rio de Janeiro (RJ)', value: 19 },
    { uf: 'RN', label: 'Rio Grande do Norte (RN)', value: 20 },
    { uf: 'RS', label: 'Rio Grande do Sul (RS)', value: 21 },
    { uf: 'RO', label: 'Rondônia (RO)', value: 22 },
    { uf: 'RR', label: 'Roraima (RR)', value: 23 },
    { uf: 'SC', label: 'Santa Catarina (SC)', value: 24 },
    { uf: 'SP', label: 'São Paulo (SP)', value: 25 },
    { uf: 'SE', label: 'Sergipe (SE)', value: 26 },
    { uf: 'TO', label: 'Tocantins (TO)', value: 27 },
    { uf: '', label: 'Não sou Brasileiro', value: 28 },
  ];

  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    data.birth_date = data.birth_date
      ? data.birth_date.toString().replace(/[^0-9/]/g, '')
      : '';

    try {
      formRef.current?.setErrors({});

      const shape = {
        name: Yup.string().required(translate('O campo Nome Completo é obrigatório')),
       
        email: Yup.string()
          .email(translate('Preencha um email válido'))
          .required(translate('O campo Email é obrigatório')),
      
          term: Yup.string().required(translate('Por favor, informe se você concorda com nossa política de dados')),
      };

      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const response = await api.post(`/signup`, {
        ...data,

        captcha: captchaValue,
      });

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao gerar seu cadastro',
      ]);
    }
    setLoading(false);
  };

  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    const state = cepResponse.uf
      ? states.find(st => {
          return st.uf === cepResponse.uf;
        })
      : false;

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      if (state) {
        formRef?.current?.setFieldValue('state', state.value);
      }
    }
  };

  return (
    <>
      {loading === true && (
        <Loader message={translate('Preparando seu cadastro...')} position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="success"
            title="Sucesso"
            description={(
              <p>
                {translate('Cadastro realizado com sucesso!')}
                <br />
                <Link to="login">
                  <Button style={{width:'100%'}} type="button">{translate('Acessar')}</Button>
                </Link>
                <br />
                {translate('Aproveite e indique para seus amigos!')}
                <Whatsapp
                  text={translate('Olá! Acabei de me inscrever no Global Stroke. Inscreva-se também!. https://globalstroke.encontrodigital.com.br')}
                  number=""
                  message={translate('Compartilhe!')}
                />
              </p>
            )}
          />
        ) : (
          <Form ref={formRef} onSubmit={handleNewLogin} initialData={{}}>


            <Input icon={FiUser} name="name" placeholder={translate('Nome Completo')} />
            <Input icon={FiMail} name="email" placeholder={translate('E-mail')} />
            
            {withPassword && (
              <Input
                icon={FiLock}
                name="password"
                type="password"
                placeholder={translate('senha')}
              />
            )}

<CheckboxInput
              style={{ display: 'flex'  }}
              name="term"
              unique={true}
              preText={(
                <>
                  {' '}
                  Estou ciente de que estou fornecendo à {authTitle} e seus parceiros meus
                  contatos gerais, os quais são dados pessoais protegidos pela
                  Lei Geral de Proteção de Dados brasileira, para gestão de
                  relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Sim, eu autorizo o compartilhamento dos meus dados`,
                  label: 'Sim, eu autorizo o compartilhamento dos meus dados',
                  value: `Sim, eu autorizo o compartilhamento dos meus dados`,
                },
                {
                  id: `Não autorizo o compartilhamento dos meus dados`,
                  label: 'Não autorizo o compartilhamento dos meus dados',
                  value: `Não autorizo o compartilhamento dos meus dados`,
                },
              ]}
            />

<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#fff',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {translate('Ao me cadastrar, afirmo que li a')}
{' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#fff' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {translate('Política de Privacidade')}
{' '}
                  </Link>
{' '}
                </strong>
                {translate('desta plataforma.')}
              </p>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
            <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

            </div>
            {errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}
            <Button style={{width: '100%'}} type="submit">{translate('Cadastrar')}</Button>
            <Link to="login">
                  <Button style={{width:'100%'}} type="button">{translate('Já sou inscrito')}</Button>
                </Link>
          </Form>
        )}
      </aside>
    </>
  );
};

export default FormContainer;
