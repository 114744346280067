import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { uuid } from 'uuidv4';
import QRCode from 'qrcode.react';
import { FaBarcode, FaQrcode } from 'react-icons/fa';
import { AxiosInstance } from 'axios';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../FormComponent';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import Button from '../../Forms/Button';

import { usePrinter } from '../../../../../../hooks/PrinterHook';
import QRCodeComponent from '../../QRCodeComponent';

interface ICreateModule {
  lineData: Record<string, any>;
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  apiRef?: AxiosInstance;
}

export default function Cracha({
  apiRef = api,
  lineData,
  title,
  formSchema,
  validationSchema,
  endpoint,
}: ICreateModule): JSX.Element {
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { addPrint, printIt } = usePrinter();
  const key = uuid();

  const print = line => {
    removeModal('crachaKey')
    api.post(`/credenciar`,{ hash : line.user_hash});
    
    printIt();
  };

  const handlePrint = async (line): Promise<void> => {



    const category = line && line.facebook && line.facebook === 'organizacao' ? 'ORGANIZAÇÃO' : line.facebook;


    
   addPrint([
    <div className="qrHolder2" style={{display: 'flex', justifyContent: 'space-between', paddingLeft:'10px', paddingRight: '10px'}}><div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
    <h2 style={{fontSize: '16px', width:'100%', textAlign: 'center', color:'#333'}}>{line?.name?.toUpperCase() || ''}</h2>
    {line?.company && <h2 style={{fontSize: '12px',  width:'100%', marginTop: '10px', textAlign: 'center', color:'#333'}}>{line?.company?.toUpperCase() || ''}</h2>}
      </div> <QRCode  size={150} value={line.user_hash}     renderAs="svg"
         />
    </div>]
    );
   

print(line);

    
  };

  const handleQR = async (line): Promise<void> => {



    const category = line && line.facebook && line.facebook === 'organizacao' ? 'ORGANIZAÇÃO' : line.facebook;


    console.log(line);
   addPrint([
    <div className="qrHolder2" style={{width:'100%', paddingTop:'10px', display: 'flex', justifyContent: 'space-between', paddingLeft:'10px', paddingRight: '10px'}}><div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}> 
    <h2 style={{fontSize: '16px', width:'100%', textAlign: 'center', color:'#333'}}>{line?.name?.toUpperCase() || ''}</h2>
    <h2 style={{fontSize: '12px',  width:'100%', marginTop: '10px', textAlign: 'center', color:'#333'}}>{line?.company?.toUpperCase() || ''}</h2>
      </div>
      <QRCode size={150} value={line.user_hash}     renderAs="svg"/>
    </div>]
    );
    const content =  <><h2>CRACHÁ</h2><div className="qrHolder2" style={{background: '#fff', display: 'flex',  width: '9cm', padding: '10px', justifyContent: 'space-between', paddingLeft:'10px', paddingRight: '10px'}}>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}><h2 style={{fontSize: '16px', width:'100%', textAlign: 'center', color:'#333'}}>{line?.name?.toUpperCase() || ''}</h2>
    <h2 style={{fontSize: '12px',  width:'100%', marginTop: '10px', textAlign: 'center', color:'#333'}}>{line?.company?.toUpperCase() || ''}</h2>
      </div><QRCode value={line.user_hash}     renderAs="svg"/>
  </div> <Button onClick={() => print(line)}>Imprimir</Button></>;

    if (content) {
      const keyModal = addModal({ title, content, key: 'crachaKey' });
    }


    
  };

  const moduleQRCode = (line): JSX.Element => (

    <button className="lineIcon"  title="Imprimir Crachá" type="button" onClick={() => handleQR(line)}>
    <FaBarcode />
  </button>
 
  );

  return moduleQRCode(lineData);
}
