import React from 'react';

import { uuid } from 'uuidv4';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';
import createModule from '../../components/SearchComponent/modules/Create';
import updateModule from '../../components/SearchComponent/modules/Update';
import updateUniqueModule from '../../components/SearchComponent/modules/UpdateUnique';

import deleteModule from '../../components/SearchComponent/modules/Delete';
import { Container, Header, Body } from './styles';
import api from '../../services/api';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

interface IRoom {
  room_id : number;
}

const Dashboard: React.FC<IRoom> = ({room_id = 0}) => {
  const endpoint = '/quizz';

  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    room_id: { ref: 'room_id', column: 'room_id', label: 'Sala', defaultValue: room_id },
    title: { ref: 'title', column: 'title', label: 'Pergunta' },
    type: { ref: 'type', column: 'type', label: 'Múltipla Escolha?', defaultValue: 1 },
    timeToClose: { ref: 'timeToClose', column: 'timeToClose', label: 'timeToClose', defaultValue: 60 },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    title: { model: 'input', type: 'text', name: 'title', label: 'Pergunta' },
    questions: {
      model: 'jsonListIncluder',
      type: 'text',
      name: 'questions',
      label: 'Opções',
      list: [
      ],
    },
    room_id: { model: 'input', type: 'text', name: 'room_id', label: 'Sala', defaultValue: room_id, hide: true },
    type: {
      model: 'selectSimple',
      type: 'text',
      name: 'type',
      label: 'Múltipla Escolha?',
       defaultValue: 1,
      options: [
        { id: 1, value: 1, label: 'Não' },
        { id: 2, value: 2, label: 'Sim' },
      ],
    },
    timeToClose: { model: 'input', type: 'text', name: 'timeToClose', label: 'Duração em segundos', defaultValue: 60 },

    

  };

  const formSchemaUpdate = {
    title: { model: 'input', type: 'text', name: 'title', label: 'Pergunta' },
    questions: {
      model: 'jsonListIncluder',
      type: 'text',
      name: 'questions',
      label: 'Opções',
      list: [
      ],
    
    },
    type: {
      model: 'selectSimple',
      type: 'text',
      name: 'type',
      label: 'Múltipla Escolha?',
       defaultValue: 1,
      options: [
        { id: 1, value: 1, label: 'Não' },
        { id: 2, value: 2, label: 'Sim' },
      ],
    },
    timeToClose: { model: 'input', type: 'text', name: 'timeToClose', label: 'Duração em segundos', defaultValue: 60 },

    
  };

  const formValidation: Record<string, ValidationSchema> = {
   
  };

  const key = uuid();

  const config: ISearchComponent = {

    idKey: key,
    title: 'Perguntas',
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        createModule({
          apiRef : api,
          title: 'Perguntas',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        updateUniqueModule({
          apiRef : api,
          lineData,
          title: 'Perguntas',
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        deleteModule({
          apiRef : api,
          lineData,
          title: 'Perguntas',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
