import React, { useCallback, useRef, useState } from 'react';
import { FiMail, FiLock, FiUser, FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { logo } from '../../config';
import { Container, Content, Background, AnimatedDiv } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';
import Whatsapp from '../../components/WhatsAppCompartilhar';

import FormContainer from '../../components/FormContainer';
import Logo from '../../components/Logo';
import { useLanguage } from '../../hooks/Language';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { translate} = useLanguage();


  return (
    <Container>
      <Content>
        <AnimatedDiv>
          <Link to="/login">
            <FiArrowLeft />
            {translate('Voltar para login')}
          </Link>

          <h2>{translate('Inscrição')}</h2>
          <FormContainer />
        </AnimatedDiv>
      </Content>
    </Container>
  );
};

export default SignUp;
