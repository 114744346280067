import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { logo } from '../../config';
import { Container, Content, Background, AnimatedDiv2 } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import { Pattern } from '../../styles/ThemeComponents';
import ShowDiv from '../../components/ShowOnScroll';
import Logo from '../../components/Logo';
import { useLanguage } from '../../hooks/Language';
import MaskedInput from '../DashboardPages/core/components/Forms/MaskedInput';
import { FaIdBadge } from 'react-icons/fa';

interface SignInData {
  email: string;
  password: string;
  name: string;
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const [withPassword, setWithPassword] = useState(false);
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const {translate} = useLanguage();

  const handleSubmit = 
    async (data: SignInData) => {
 
      try {
        formRef.current?.setErrors({});

        const shape = withPassword
          ? {
              email: Yup.string().required(translate('CPF obrigatório')),
              
              password: Yup.string().required(translate('Senha obrigatória')),
            }
          : {
              email: Yup.string().required(translate('Preencha um CPF válido')),
            };

        
        const schema = Yup.object().shape(shape);

        await schema.validate(data, { abortEarly: false });

        const { email, name } = data;

        await signIn({ email, password: '', name });
        window.location.href = '/dashboard';
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: translate('Erro na autenticação'),
          description: translate('Ocorreu um erro ao fazer login'),
        });
      }
    }

  return (
    <Container>
      <Content>
        <AnimatedDiv2>
         

         {/* <Form ref={formRef} onSubmit={handleSubmit}>
            <h2>{translate('Acessar')}</h2>

            <MaskedInput icon={FaIdBadge} name="email" placeholder={translate('CPF')} mask="999.999.999-99" />
            {withPassword && (
              <Input
                icon={FiLock}
                name="password"
                type="password"
                placeholder={translate('senha')}
              />
            )}
            <Button type="submit" style={{width:"100%"}}>{translate('Entrar')}</Button>

      
            </Form> */}
          <h2 style={{fontSize: '32px'}}>Evento encerrado</h2>
          <p style={{fontSize:'18px'}}>Obrigado por participar</p>
        </AnimatedDiv2>
      </Content>
    </Container>
  );
};
export default SignIn;
