import styled from 'styled-components';
import { background } from '../../../config';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  padding-top: 0px;
  background: none;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  min-height: auto;
  z-index: -1;
`;

export const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

export const Footer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 10px;
  background: #fff;
  color: rgb(0, 100, 150);
  min-height: 100px;
  z-index: 200;
  position: absolute;
  bottom: 0px;
  left: 0px;

  > p {
    position: relative;
    z-index: 200;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;
