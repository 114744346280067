import React, { useEffect } from 'react';
import { useLanguage } from '../../hooks/Language';
import {Container, Sponsor, SponsorGrid, ApoioGrid, Apoio, Apoio2 } from './styles';




const org = [
  { image : '/apoio/world.png', url: ''},
  { image : '/apoio/siecv.png', url: ''}
  ];

const apoios = [
  { image : '/apoio/mt.png', url: ''},
  { image : '/apoio/svin.png', url: ''}
  ];
  
  export const sponsors = [
    { image : '/apoio/boehring.png', url: ''},
    { image : '/apoio/medtronic.png', url: ''},
    { image : '/apoio/rapid.png', url: ''},
    { image : '/apoio/penumbra.jpeg', url: ''},
  ]
  


const FooterLayout: React.FC = ({ children }) => {
const {translate } = useLanguage();
  return (

<Container style={{background:'#fff'}}>
<img className="computerOnly" src="/apoio/rodape_logos2.jpg" style={{width: '100%'}}/>
<img className="mobileOnly" src="/apoio/rodape_logos_mobile2.jpg" style={{width: '100%'}}/>

        </Container>
  );
};

export default FooterLayout;
