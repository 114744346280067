import React, {useRef, PureComponent,useEffect, useState} from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import {Container} from './styles';
import "videojs-contrib-hls";
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";
import { urlWebsite } from '../../config';
import Axios from 'axios';
import urlExist from 'url-exist';
interface IProps {
    url : string;
    keyName : string;
    type ?: string;
    prefix ?: string;
    image ?: string;
}

interface ExtendedVideoJS extends VideoJsPlayer {
  httpSourceSelector ?: Function;
}


const VideoPlayer : React.FC<IProps> = ({prefix,url,keyName, type = 'application/x-mpegURL', image = '/assets/evento/logo.jpeg' }) => {
  
    const [muted,setMuted] = useState(false);
    const playerRef = useRef<HTMLVideoElement>(null);
    const [getPlayer,setGetPlayer] = useState<ExtendedVideoJS>();
    const [quality,setQuality] = useState('');
    const [videoOK,setVideoOK] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
  const play = (ref) => {
    ref?.play();
  }

    useEffect(() => {
if(videoOK){
 if(playerRef && playerRef.current){
      const player = videojs(playerRef.current , { muted: false, plugins: {
        httpSourceSelector:
        {
          default: 'low'
        }
      } }, () => {
  player.src({
    src: `${prefix}${url}${quality ? `${quality}.m3u8` : '.m3u8'}`,
    type: type

  })

  player.on('loadedmetadata',function(){
    console.log('loadedmetadata');
    //After loading to metadata, start playing video
    player.play();
})

  player.on('error', function() {

    console.log(player.error());
   return;
  });
  
  player.poster(`${urlWebsite}/${image}`);


})

let timer = 0;
player.on(['timeupdate'], function() {
  if (player.currentTime() === timer) {
   
  }
  else{
   
  }
});



setGetPlayer(player);

const root = document.getElementById('root');
root?.click();
setTimeout(() => {
  
  player.muted(false);
},2000)

}

      return () => {
      }
   
    
      }

    }, []);

 

    useEffect(() => {
      if(videoOK){
      if(playerRef && playerRef.current){
        const player = videojs(playerRef.current , { autoplay: true, muted: false, plugins: {
          httpSourceSelector:
          {
            default: 'low'
          }
        } }, () => {
    player.src({
      src: `${prefix}${url}${quality ? `${quality}.m3u8` : '.m3u8'}`,
      type: type
  
    })
  
    player.poster(`${urlWebsite}/${image}`);
    const myButton = player.controlBar.addChild('button', {
      value: 'Hi'
      // other options
    });

    player.on('error', function() {
      console.log(player.error());
      player.pause();
    });

    console.log(player.getVideoPlaybackQuality());
  
  myButton.addClass("html-classname");
   /* player.on(['waiting', 'pause'], function() {
      setMuted(true);
    }); */

   
    

    player.on('loadedmetadata',function(){
      console.log('loadedmetadata');
      //After loading to metadata, start playing video
      player.play();
  })

    let timer = 0;

    player.on(['timeupdate'], function() {
      
    });
    
    const root = document.getElementById('root');
    root?.click();
    setTimeout(() => {
     
      player.muted(false);
    },2000)
  
  })
  
  setGetPlayer(player);
  

  
  
  }

      }
  return () => {
    }

  
    }, [keyName, videoOK,url]);


  useEffect(() => {
    if(getPlayer && getPlayer.httpSourceSelector){
      getPlayer.httpSourceSelector();
    }

  },[getPlayer])

    const changeQuality = (value) => {
      console.log(value);
      setQuality(value);
  
    }


    const fullScreen = () => {

 
   
        if (document.fullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } 
        } else {
          const id = document.getElementById(`videoContainer`);
          console.log(id);
          id?.requestFullscreen();
  
    }
  }

  useEffect(() => {


    const timer = setInterval(() => {
      urlExist(url).then((status) => {
        console.log(status);
        if(status) {
          clearInterval(timer);
          setVideoOK(true);
        }
      });
    },2000);
  }, [])
  
    return (<Container>
      <div   style={{position: 'relative'}}>
{!videoOK && <div style={{background:'rgba(0,0,0,0.3)', display:'flex', alignItems: 'center', position:'relative'}}>
  <img style={{width: 'auto', margin: '0px auto', maxHeight:'500px', maxWidth:'100%'}} src={`${urlWebsite}/${image}`}/>
  <h2 style={{position: 'absolute', background: 'rgba(0,0,0,0.7)', borderRadius:'10px', padding: '20px', top: '50%', left: '50%', transform:'translateX(-50%)'}}>Transmissão ainda não iniciou</h2></div>}
        { videoOK && 
      <div key={`${keyName}-${quality}`}  data-vjs-player>
       
        <video ref={playerRef} autoPlay className="video-js vjs-16-9" controls playsInline/>

      </div> }

      </div>
     
      </Container>
    );
  }

  export default VideoPlayer;