import styled from 'styled-components';
import { shade } from 'polished';

export const Button = styled.button`
       background: #ffba1c !important;
      color: #000e7c  !important;
  height: 56px;

  border-radius: 0px;
  border: 0;
  padding: 0 16px;
  min-width: 100px;
  max-width: 300px;
  font-weight: 500;
  margin-top: 16px;

  transition: background-color 0.2s;

  border: 1px solid #000e7c !important;

  &:hover {
    background-color: ${shade(0.2, '#ffba1c')};
  }
`;
