import React, { useState, useEffect } from 'react';

import {
  FaPlus,
  FaTrash,
  FaQuestion,
  FaList,
  FaChartPie,
  FaEye,
  FaBroom,
} from 'react-icons/fa';
import { FiX, FiSend } from 'react-icons/fi';
import { Container, Field, Modal, QuizzHeader } from './styles';
import { useModal } from '../../hooks/Modal';
import { useToast } from '../../hooks/Toast';
import { logo } from '../../config';
import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';

import { useSocket } from '../../hooks/Socket';

interface IID {
  roomNumber: number;
}

interface IAnswers {
  name: string;
  value?: string;
  label?: string;
}

interface IQuestions {
  id?: string;
  title: string;
  type: number;
  questions: Array<Record<string, any>>;
}

const QuizzComponent: React.FC<IID> = ({ roomNumber = 0 }) => {
  const { socket } = useSocket();
  const { user } = useAuth();
  const [room_id, setRoom_id] = useState(roomNumber || 0);

  useEffect(() => {
    socket.on('startQuizz', data => {
      if (data && data.id) {
        getQuestionToVote(data.id);
      }
    });

    socket.on('showQuizzReport', data => {
      if (data) {
        readQuizzReport(data);
      }
    });
    

  }, []);

  useEffect(() => {
    setRoom_id(roomNumber);
  }, [roomNumber]);

  const { addToast } = useToast();

  const createID = () => `p${new Date().getUTCMilliseconds()}`;
  let selectedOptionVote: Array<string> = [];
  const [votation, setVotation] = useState<JSX.Element>();
  const [votationActive, setVotationActive] = useState(false);

  const [reportVotation, setReportVotation] = useState<JSX.Element>();
  const [reportVotationActive, setReportVotationActive] = useState(false);

  const [deleteItem, setDeleteItem] = useState<JSX.Element>();
  const [deleteItemActive, setDeleteItemActive] = useState(false);

  const [questionList, setQuestionList] = useState<Array<IQuestions>>([]);
  const [questionListActive, setQuestionListActive] = useState(false);

  const [question, setQuestion] = useState('');
  const [room, setRoom] = useState(room_id || 0);
  const [type, setType] = useState(1);

  const [answers, setAnswers] = useState<Array<IAnswers>>([
    { name: createID(), label: '', value: '' },
  ]);
  const [active, setActive] = useState(false);
  const { addModal } = useModal();

  /* EXCLUI UMA ENQUETE */

  const askDeleteQuizz = id => {
    const item = (
      <>
        <p />
        <h2>Confirmar exclusão?</h2>

        <button
          type="button"
          onClick={() => setDeleteItemActive(false)}
          className="saveButton"
        >
          Não
        </button>
        <button
          type="button"
          onClick={() => deleteQuizz(id)}
          className="saveButton"
        >
          Sim
        </button>
      </>
    );

    setDeleteItemActive(true);
    setDeleteItem(item);
  };


  const askClearQuizz = id => {
    const item = (
      <>
        <p />
        <h2>Confirmar Limpar respostas?</h2>

        <button
          type="button"
          onClick={() => setDeleteItemActive(false)}
          className="saveButton"
        >
          Não
        </button>
        <button
          type="button"
          onClick={() => clearQuizz(id)}
          className="saveButton"
        >
          Sim
        </button>
      </>
    );

    setDeleteItemActive(true);
    setDeleteItem(item);
  };


  const deleteQuizz = async id => {
    const response = await api.delete(`/quizz/${id}`);
    if (response.status !== 200) {
      return addToast({ type: 'error', title: 'Erro ao remover' });
    }

    listQuestions(room_id);

    setDeleteItemActive(false);
    setDeleteItem(<></>);
    return addToast({ type: 'success', title: 'Removido com sucesso' });
  };

  const clearQuizz = async id => {
    const response = await api.delete(`/answers/${id}`);
    if (response.status !== 200) {
      return addToast({ type: 'error', title: 'Erro ao remover' });
    }

    listQuestions(room_id);

    setDeleteItemActive(false);
    setDeleteItem(<></>);
    return addToast({ type: 'success', title: 'Limpo com sucesso' });
  };

  /* ENVIA PARA OS PARTICIPANTES */
  const sendQuizzToParticipants = (id, room_id) => {
    socket.emit('sendQuizz', { id, room_id });
  };

  /* RECUPERA O RELATÓRIO */

  const readReport = report => {
    console.log(report);
    const counter = report.report.reduce((prev, con) => {
      prev += con.count;

      return prev;
    }, 0);

    return (
      <>
        <p>Pergunta</p>
        <h2>{report.title}</h2>
        <Field>
          <div className="voteOption">
            <button
              className="iconButton"
              style={{ marginRight: '5px', padding:'3px', width: 'auto' }}
              type="button"
            >
              {report.users}
            </button>
{' '}
            <span>Usuários participaram</span>
          </div>
        </Field>
        <p>Resultados</p>
        {report.report.map(quest => {
          const percentage = (quest.count / counter) * 100 || 0;
          return (
            <Field>
              <div className="voteOption">
                <button
                  className="iconButton"
                  style={{ marginRight: '5px', padding:'3px', width: 'auto', minWidth:'100px' }}
                  type="button"
                >
                  {percentage.toFixed(2)} %
                </button>
{' '}
                <span>{quest.title}</span>
              </div>
            </Field>
          );
        })}
      </>
    );
  };

  const getReport = async (id,type = 1) => {
    selectedOptionVote = [];
    setVotation(<></>);
    setVotationActive(false);
    const response = await api.get(`/quizz_answer_report/${id}`);
    if (response.status !== 200) {
      return addToast({ type: 'error', title: 'Erro ao recuperar resultados' });
    }

    if(type === 1){
     
      readQuizzReport(response);
    }
    else{

      if (response) {
        socket.emit('sendQuizzReport', { room_id, data : response.data})
      }
    }

  };

  const readQuizzReport = (data) => {
    if(data && data.data){
      const Item = readReport(data.data);
      setReportVotation(Item);
      setReportVotationActive(true);
    }
  }

  /* LISTAGEM DE ENQUETES */

  const listQuestions = async idRef => {
    const response = await api.get('/quizz', {
      params: { where: { room_id: idRef } },
    });

    console.log(idRef);
    if (response.status !== 200) {
      return addToast({ type: 'error', title: 'Erro ao pesquisar' });
    }

    if (response) {
      setQuestionList(response.data.rows || []);
      setQuestionListActive(true);

      setActive(false);
    }
  };

  /* GERAR SELEÇÃO */

  const checkVoteSelected = value => {
    const index = selectedOptionVote.findIndex(select => select === value);

    if (index >= 0) {
      return true;
    }
    return false;
  };

  const read = questionObj => {
    return (
      <>
        <p>Pergunta</p>
        <h2>{questionObj.title}</h2>
        <p>Opções</p>
        {questionObj.questions.map(quest => {
          const checked = checkVoteSelected(quest.value);
          console.log(checked);
          return (
            <Field>
              <div className="voteOption">
                <input
                  name="option"
                  defaultChecked={checked}
                  type={questionObj.type === 1 ? 'radio' : 'checkbox'}
                  onClick={() => selectOption(questionObj, quest.value)}
                  className="iconButton"
                  value={quest.value}
                />
{' '}
                <span>{quest.value}</span>
              </div>
            </Field>
          );
        })}

        <button
          type="button"
          onClick={() => sendVote(questionObj.id)}
          className="saveButton"
        >
          Enviar
        </button>
      </>
    );
  };

  const selectOption = (questionObj, value: string) => {
    if (questionObj.type === 1) {
      const vote: Array<string> = [];
      vote.push(value);
      selectedOptionVote = vote;
    } else {
      const index = selectedOptionVote.findIndex(select => select === value);

      if (index >= 0) {
        const votes = [...selectedOptionVote];
        votes.splice(index, 1);
        selectedOptionVote = votes || [];
      } else {
        selectedOptionVote = [...selectedOptionVote, value];
      }
    }

    read(questionObj);
  };

  const sendVote = async id => {
    if (selectedOptionVote.length < 1) {
      return addToast({
        type: 'error',
        title: 'Vote em ao menos 1 das alternativas',
      });
    }

    const data = {
      answers: selectedOptionVote,
      id,
    };
    try {
      const response = await api.post('/quizz_answer', data);
      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao votar' });
      }

      setVotationActive(false);

      addToast({ type: 'success', title: 'Enviado com sucesso' });
    } catch (err) {
      return addToast({ type: 'error', title: err.response.data.message });
    }
  };

  const getQuestionToVote = async id => {
    selectedOptionVote = [];
    setVotation(<></>);
    setVotationActive(false);
    const response = await api.get(`/quizz/${id}`);
    if (response.status !== 200) {
      return addToast({ type: 'error', title: 'Erro ao pesquisar' });
    }

    if (response) {
      const Item = read(response.data);
      setVotation(Item);
      setVotationActive(true);
    }
  };

  const addAnswer = () => {
    const newID = createID();
    setAnswers([...answers, { name: newID, label: '', value: '' }]);
  };

  const updateAnswerValue = (key, value) => {
    const index = answers.findIndex(answer => answer.name === key);

    if (index >= 0) {
      const newValue = [...answers];
      newValue[index].value = value;

      setAnswers([...newValue]);
    }
  };

  const removeAnswer = (key: string) => {
    const { length } = answers;
    if (length >= 1) {
      const index = answers.findIndex(answer => answer.name === key);

      if (index >= 0) {
        const newValue = [...answers];
        newValue.splice(index, 1);

        console.log(newValue);

        setAnswers([...newValue]);
      }
    }
  };

  const addQuestion = () => {
    setQuestion('');
    setAnswers([{ name: createID(), label: '', value: '' }]);
    setType(1);
    setQuestionListActive(false);
    setActive(true);
  };

  const send = async (send = false) => {
    if (!question) {
      return addToast({ type: 'error', title: 'Campo Pergunta é obrigatório' });
    }

    const data = {
      title: question,
      room_id,
      questions: answers,
      type,
    };
    try {
      const response = await api.post('/quizz', data);
      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

      setActive(false);

      addToast({ type: 'success', title: 'Salvo com sucesso' });

      if (send === true) {
        sendQuizzToParticipants(response.data.id, room_id);
      }
    } catch (err) {
      return addToast({ type: 'error', title: err.response.data.error });
    }
  };

  return (
    <Container>
      {active && (
        <Modal style={{ justifyContent: 'flex-start', paddingTop: '30px' }}>
          <div>
            <h2>Criar Pergunta</h2>
            <Field>
              <label>Pergunta</label>
              <textarea
                onChange={e => setQuestion(e.target.value)}
                value={question}
                placeholder="Pergunta"
              />
            </Field>

            <Field className="field">
              <label onClick={() => addAnswer()}>
                Respostas
{' '}
                <button className="iconButton" type="button">
                  <FaPlus />
                </button>
              </label>
              {answers.map(ans => (
                <div>
                  {' '}
                  <input
                    type="text"
                    name={ans.name}
                    value={ans.value}
                    onChange={e => updateAnswerValue(ans.name, e.target.value)}
                    placeholder="Resposta"
                  />
                  <button
                    className="iconButton iconTrash"
                    type="button"
                    onClick={() => removeAnswer(ans.name)}
                  >
                    <FaTrash />
                  </button>
                </div>
              ))}
            </Field>

            <Field>
              <label>Múltipla Escolha?</label>
              <select onChange={e => setType(parseInt(e.target.value))}>
                <option selected={type === 1} value="1">
                  Não
                </option>
                <option selected={type === 2} value="2">
                  Sim
                </option>
              </select>
            </Field>

            <button type="button" onClick={() => send(false)}>
              Salvar
            </button>
            <button type="button" onClick={() => send(true)}>
              Salvar e enviar
            </button>
          </div>
          <button
            className="closeButton"
            type="button"
            onClick={() => setActive(false)}
          >
            Fechar
          </button>
        </Modal>
      )}
      {user &&
        (user.profile === 1 || user.profile === 3 || user.profile === 4 || user.profile === 6 || user.profile === 9) && (
          <QuizzHeader>
            {' '}
            <button
              className="defaultButton"
              onClick={() => addQuestion()}
              type="button"
            >
              <FaQuestion /> Criar Pergunta
            </button>
            <button
              className="defaultButton"
              onClick={() => listQuestions(room_id)}
              type="button"
            >
              <FaList />
              Listar Enquetes
            </button>
{' '}
          </QuizzHeader>
        )}

      {questionListActive && (
        <Modal>
          <div>
            <h2>Enquetes</h2>
            <table style={{ borderCollapse: 'collapse' }}>
              <tbody>
                {questionList.map(quest => (
                  <tr style={{ borderBottom: '2px solid #333' }}>
                    <td style={{ padding: '10px' }}>
                      <div> {quest.title} </div>
                      <div className="ListIcons" style={{ display: 'flex' }}>
                        <button
                          title="Ver Resultado"
                          onClick={() => getReport(quest.id,1)}
                          type="button"
                          className="defaultButtonMini"
                        >
                          <FaChartPie />
                        </button>
                        <button
                        style={{background:'rgb(0,100,200)', color:'#fff'}}
                          title="Enviar resultado para todos"
                          onClick={() => getReport(quest.id,2)}
                          type="button"
                          className="defaultButtonMini"
                        >
                          <FaChartPie />
                        </button>

                        <button
                          title="Excluir"
                          onClick={() => askDeleteQuizz(quest.id)}
                          type="button"
                          className="defaultButtonMini danger"
                        >
                          <FaTrash />
                        </button>

                        <button
                          title="Limpar"
                          onClick={() => askClearQuizz(quest.id)}
                          type="button"
                          className="defaultButtonMini danger"
                        >
                          <FaBroom/>
                        </button>

                        <button
                          title="Visualizar Pergunta"
                          onClick={() => getQuestionToVote(quest.id)}
                          type="button"
                          className="defaultButtonMini"
                        >
                          <FaEye />
                        </button>

                        <button
                          onClick={() =>
                            sendQuizzToParticipants(quest.id, room_id)}
                          type="button"
                          className="defaultButtonMini"
                        >
                          <FiSend />
{' '}
Enviar
</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            className="closeButton"
            type="button"
            onClick={() => setQuestionListActive(false)}
          >
            Fechar
          </button>
        </Modal>
      )}

      {votationActive && (
        <Modal>
          <div>{votation}</div>
          <button
            className="closeButton"
            type="button"
            onClick={() => setVotationActive(false)}
          >
            Fechar
          </button>
        </Modal>
      )}

      {reportVotationActive && (
        <Modal>
          <div>{reportVotation}</div>
          <button
            className="closeButton"
            type="button"
            onClick={() => setReportVotationActive(false)}
          >
            Fechar
          </button>
        </Modal>
      )}

      {deleteItemActive && (
        <Modal>
          <div>{deleteItem}</div>
          <button
            className="closeButton"
            type="button"
            onClick={() => setDeleteItemActive(false)}
          >
            Fechar
          </button>
        </Modal>
      )}
    </Container>
  );
};

export default QuizzComponent;
