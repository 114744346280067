const program = { title : 'Hub Pelotas Parque Tecnológico',
    date: '10/09 sexta-feira',
    description : `Av. Domingos de Almeida, 1785 – Pelotas/RS<br/>
    Em meio ao ecossistema de tecnologia, o Hub Pelotas Parque Tecnológico estimulará a criação de novas soluções que atendam o presente e o futuro, integrando a comunidade acadêmica aos setores público e privado que circulam pelo espaço. O espaço contará com a transmissão da programação do Insight House e atividades in loco como palestras e oficinas.
    `,
    image : '/botao/botao_programacao_hub_pelotas.png',
    day: '10',
    city: 'Pelotas',
    activity : [
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '08:00 às 08:50',
            title : 'Credenciamento',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '09:00 às 09:50',
            title : 'Transmissão Insight House',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '10:00 às 10:50',
            title : 'Transmissão Insight House',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '11:00 às 11:50',
            title : 'Economia Criativa',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '12:00 às 13:50',
            title : 'Intervalo',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '14:00 às 14:50',
            title : 'Transmissão Insight House ',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '15:00 às 15:50',
            title : 'Painel O papel dos Parques Tecnológicos e Incubadoras de Empresas na Nova Economia -Parques UNISC, UNIVATES, UFPEL e FURG',
            subtitle: 'Mediação: Gustavo Moreira',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '16:00 às 16:50',
            title : 'Painel Mulheres do Brasil',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '17:00 às 17:50',
            title : 'Transmissão Insight House',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '18:00 às 19:00',
            title : 'Intervalo',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '19:00 às 20:30',
            title : 'Painel Inovação Aberta como alavanca de Empreendedorismo e Competitividade com Elias Graziotti Rigon (Badesul), Mirian Souza (Mentora de Startup e TEDX Speaker) e Ricardo Ramos (Consultor em Estratégia de Inovação)',
            subtitle: 'Mediação: Marcele Guarenti (CEO da Connection)',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '20:30',
            title : 'Encerramento',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Atrações',
            room_color: 'rgb(0, 0, 100)',
            date_text : '',
            title : `Oficinas Maker <br/> <br/>
            Mostra de 5 Startups <br/>
            Connection <br/>
Feira Online Orgânico Solidário <br/>
Plataforma Músicos <br/>
Assimétrico/a Podcast <br/>
TS5-Tecnologias e Inovação em Saúde
            `,
            subtitle: '',
            description : '',
            photos : []
        },
    ]

}

export default program;