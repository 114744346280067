import React, { useEffect, useState } from 'react';

import {
  FaWhatsapp,
  FaTelegram,
  FaInstagram,
  FaFacebookSquare,
  FaHamburger,
  FaSignInAlt,
  FaSignOutAlt,
  FaSign,
  FaGoogle,
  FaLinkedin,
  FaHome,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';

import { MdMenu } from 'react-icons/md';
import Logo from '../Logo';

import { useAuth } from '../../hooks/Auth';
import { eventName, logo, social } from '../../config';
import {
  Container,
  Navigation,
  NavigationList,
  LinkLi as Link,
  MenuIcon,
  Icon,
  MobileMenuBackground,
  TextMessage,
  IconSet,
  LanguageSet,
} from './styles';

import Whatsapp from '../Whatsapp';
import { useLanguage } from '../../hooks/Language';
import QRCodeModule from '../../pages/DashboardPages/core/components/lineModules/QrCode';
import userEvent from '@testing-library/user-event';
import QRCodeChecker from '../../pages/DashboardPages/features/QrCodeChecker';

interface IOffset extends Element {
  offsetTop: number;
}

export function scrollTo(
  e2: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  targetID2: string,
  duration2 = 800,
): void {
  const scrollToTarget = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    targetID: string,
    duration = 800,
  ): void => {
    e.preventDefault();
    const target = document.querySelector<IOffset>(`#${targetID}`);
    const position = target && target.offsetTop ? target.offsetTop - 60 : 0;
    const offset = position;

    const startPosition = window.pageYOffset;

    const distance = offset - startPosition;
    let startTime = 0;

    const ease = (t: number, b: number, c: number, d: number): number => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animation = (currentTime = 0): void => {
      if (startTime === 0) {
        startTime = currentTime;
      }

      const timeElapsed = currentTime - startTime;

      const run = ease(timeElapsed, startPosition, distance, duration);

      window.scrollTo(0, run);

      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    };
    requestAnimationFrame(animation);
  };

  scrollToTarget(e2, targetID2, duration2);
}

const WhatsappIcon: React.FC = () => {
  return (
    <Icon
      style={{ margin: '0px' }}
      background="rgb(0,150,100)"
      title="Whatsapp"
      href="https://api.whatsapp.com/send?l=pt&phone=5551981476007"
    >
      <FaWhatsapp />
    </Icon>
  );
};

interface IIcons {
  message?: string;
  showText?: boolean;
  backgroundFill?: boolean;
  socialIcons?: Record<string, any>;
}

const ContactIcons: React.FC<IIcons> = ({
  message = '',
  showText = false,
  backgroundFill = true,
  socialIcons = social,
}) => {
  return (
    socialIcons && (
      <IconSet column={showText}>
        {message !== '' && (
          <div className="messageSet">
            <TextMessage>{message}</TextMessage>
          </div>
        )}
        <div className="iconSet">
          {socialIcons.facebook && (
            <Icon
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Facebook"
              href={`https://www.facebook.com/${socialIcons.facebook}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaFacebookSquare />
{' '}
{showText && <span>Facebook</span>}
            </Icon>
          )}

          {socialIcons.instagram && (
            <Icon
              extend={showText}
              className="socialIcon"
              title="Instagram"
              target="_blank"
              href={`https://www.instagram.com/${socialIcons.instagram}`}
              background={backgroundFill === true ? 'rgb(250,0,100)' : 'none'}
            >
              <FaInstagram />
{' '}
{showText && <span>Instagram</span>}
            </Icon>
          )}

          {socialIcons.linkedin && (
            <Icon
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Linkedin"
              href={`https://www.linkedin.com/${socialIcons.linkedin}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaLinkedin />
{' '}
{showText && <span>Linkedin</span>}
            </Icon>
          )}

          {socialIcons.youtube && (
            <Icon
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Youtube"
              href={`https://www.youtube.com/${socialIcons.youtube}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaYoutube />
{' '}
{showText && <span>Youtube</span>}
            </Icon>
          )}

          {socialIcons.twitter && (
            <Icon
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Twitter"
              href={`https://www.twitter.com/${socialIcons.twitter}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaTwitter /> {showText && <span>Twitter</span>}
            </Icon>
          )}

          {socialIcons.whatsapp && (
            <Icon
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Whatsapp"
              href={`https://api.whatsapp.com/send?l=pt&phone=55${
                socialIcons.whatsapp
              }&text=${encodeURI(`Olá! Estou no evento ${eventName}!`)}`}
            >
              <FaWhatsapp />

              {showText && <span>Whatsapp</span>}
            </Icon>
          )}

          {socialIcons.website && (
            <Icon
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Website"
              href={socialIcons.website}
            >
              <FaHome />

              {showText && <span>Website</span>}
            </Icon>
          )}

          {socialIcons.zoom && (
            <Icon
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Zoom"
              href={socialIcons.zoom}
            >
              <FaLinkedin />

              {showText && <span>Zoom</span>}
            </Icon>
          )}

          {socialIcons.meet && (
            <Icon
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Reunião no Goole Meet"
              href={socialIcons.meet}
            >
              <FaGoogle />

              {showText && <span>Google Meet</span>}
            </Icon>
          )}
        </div>
      </IconSet>
    )
  );
};

const LanguageIcons: React.FC = () => {
  const { changeLanguage, language } = useLanguage();
  return (
    <LanguageSet>
      <div style={{borderBottom : language === 'ptBr' ? '4px solid rgb(0,150,250)' : '0px'}} onClick={() => changeLanguage('ptBr')}>
        <img
          style={{ margin: 0 }}
          src="/assets/languages/BR.png"
          alt="Portuguese"
        />
      </div>
  
      <div style={{borderBottom : language === 'es' ? '4px solid rgb(0,150,250)' : '0px'}} onClick={() => changeLanguage('es')}>
        <img
          style={{ margin: 0 }}
          src="/assets/languages/ES.png"
          alt="Spanish"
        />
      </div>
    </LanguageSet>
  );
};

interface IMenu {
  menuList: Record<string, any>;
  fixedMenu: boolean;
}

const Menu: React.FC<IMenu> = ({ menuList, fixedMenu = true }) => {
  const [sticky, setSticky] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [haveQr, setHaveQr] = useState(true);
  const {user} = useAuth();

  const LoginIcons: React.FC = () => {
    const { signOut, token } = useAuth();
    const { translate } = useLanguage();

    return (
      <>
        {' '}
        {token && (
          <li key="logout">
            <Link
              onClick={e => {
                signOut();
                setActiveMenu(false);
              }}
              to="/"
              title="Sair"
            >
              {translate('Sair')}
            </Link>
          </li>
        )}
      </>
    );
  };

  useEffect(() => {
    if (fixedMenu) {
      const header = document.querySelector<IOffset>('.menuHeader');

      const offsetValue = header?.offsetTop || 0;

      const offsetMonitor = (): void => {
        if (window.pageYOffset > offsetValue) {
          setSticky(true);
        } else {
          setSticky(false);
        }
      };

      window.onscroll = () => {
        offsetMonitor();
      };
    }
  }, [fixedMenu, sticky]);

  const handleActiveMenu = (): void => {
    setActiveMenu(!activeMenu);
  };

  return (
    <Container
      sticky={sticky}
      fixedMenu={fixedMenu}
      activeMenu={activeMenu}
      className="menuHeader"
    >
      <Navigation sticky={sticky} fixedMenu={fixedMenu}>
        <div style={{ display: 'flex', alignItems: 'strech' }}>
          {' '}
         <a style={{ display: 'flex' }} href="/" title="Home">
            <img src="/apoio/sebrae.png" style={{width:'120px'}}/>
          </a>
        </div>
        <MobileMenuBackground activeMenu={activeMenu} />
        <NavigationList activeMenu={activeMenu}>
          {menuList.map((menu: Record<string, any>) => {
            const {
              title,
              target,
              icon,
              targetID = null,
              fluid = 1,
              external = 1,
              status = 1,
            } = menu;

            const LinkMe: React.FC = () => {
              const {translate} = useLanguage();
              if (status === 2 && fluid === 2) {
                return (
                  <Link
                    onClick={e => {
                      if (window.location.pathname === '/') {
                        setActiveMenu(false);
                        scrollTo(e, targetID);
                      } else {
                        setActiveMenu(false);
                        window.location.href = `${target}#${targetID}`;
                      }
                    }}
                    to={target}
                    title={translate(title)}
                  >
                    {icon}
                    {translate(title)}
                  </Link>
                );
              }
              if (status === 2 && external === 2) {
                return (
                  <a
                    title={translate(title)}
                    href={target}
                    onClick={() => setActiveMenu(false)}
                  >
                    {icon}

                    {translate(title)}
                  </a>
                );
              }
              if (status === 2) {
                return (
                  <Link
                    title={translate(title)}
                    to={target}
                    onClick={() => setActiveMenu(false)}
                  >
                    {icon}

                    {translate(title)}
                  </Link>
                );
              }
              return <></>;
            };

            return (
              <>
                {' '}
                {status === 2 && (
                  <li key={target}>
                    <LinkMe />
                  </li>
                )}
              </>
            );
          })}

          <li className="closeMenu" onClick={() => handleActiveMenu()}>
            {'<<'}
            Voltar
          </li>

          <div className="row">
            
            <LoginIcons />
            <ContactIcons />
            {haveQr && user && user.user_hash && <QRCodeChecker lineData={{user_hash : user.user_hash}}/>}
          </div>
        </NavigationList>

        <MenuIcon>
        {haveQr && user && user.user_hash && <QRCodeChecker lineData={{user_hash : user.user_hash}}/>}
          <MdMenu onClick={() => handleActiveMenu()} size="40px" />
        </MenuIcon>
      </Navigation>
    </Container>
  );
};

export { WhatsappIcon, ContactIcons, Menu };
