import React from 'react';
import { Switch } from 'react-router-dom';
import { home } from '../config';
import Route from './routes';
import SignIn from '../pages/SignIn';
import Sobre from '../pages/Sobre';
import SignInAdmin from '../pages/SignInAdmin';
import SignUp from '../pages/SignUp';
import RecoverPassword from '../pages/RecoverPassword';
import ResetPassword from '../pages/ResetPassword';
import Products from '../pages/Products';

import VideosDashboard from '../pages/VideosDashboard';
import DashboardPage from '../pages/DashboardPages';

import Sale from '../pages/Sale';
import Course from '../pages/Course';
import Lesson from '../pages/Lesson';
import Home from '../pages/Home';
import AreaExposicao from '../pages/AreaExposicao';
import Expositor from '../pages/Expositor';
import Galeria from '../pages/Galeria';
import ExpositorChat from '../pages/ExpositorChat';
import SigninExpositor from '../pages/SigninExpositor';
import Program from '../pages/Program/index2';

import QuizzAdmin from '../pages/QuizzAdmin';
import Zoom from '../components/ZoomStarter';
import SpeakerLink from '../pages/SpeakerLink';
import Certificados from '../pages/Certificados';

import Termos from '../pages/Termos';
import Programacao from '../pages/Programacao';
import ModeratorLink from '../pages/ModeratorLink';
import Palestrantes from '../pages/Palestrantes';
import CheckinQR from '../pages/DashboardPages/features/CheckinQR';
import Validate from '../pages/DashboardPages/features/AuthIntegration';

const ValidPaths = {
  SignIn,
  SignInAdmin,
  SignUp,
  RecoverPassword,
  ResetPassword,
  Products,
  VideosDashboard,

  Sale,
  Course,
  Lesson,
  Home,
  Sobre,
};

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/"
        exact
        component={ValidPaths[home] ? ValidPaths[home] : Home}
      />
      <Route path="/events/access/:hash" component={SpeakerLink} />
      <Route path="/moderator/access/:hash" component={ModeratorLink} />
      <Route path="/zoom" component={Zoom} />
      <Route path="/programacao" component={Programacao} />
      <Route path="/quizz" component={() => <QuizzAdmin room_id={2}/>} />
      <Route path="/login" component={SignIn} />
      <Route path="/exposicao" component={AreaExposicao} />
      <Route path="/certificados" component={Certificados} />
      <Route path="/programacao" component={Program} />

      <Route path="/palestrantes/:speaker" component={Palestrantes} />
      <Route path="/palestrantes" component={Palestrantes} />
      <Route path="/admin" component={SignInAdmin} />
      <Route path="/login_expositor" component={SigninExpositor} />

      <Route path="/recuperar_senha" component={RecoverPassword} />
      <Route path="/reset_password/:key?" component={ResetPassword} />
      <Route path="/comprar" template="PageLayout" component={Products} />
      <Route path="/termos" component={Termos} />
      <Route
        path="/expositores/:link_name"
        template="page"
        component={ExpositorChat}
      />
      <Route path="/galeria/:link_name" template="page" component={Galeria} />
      <Route
        path="/expositores_chat/:link_name"
        template="page"
        component={ExpositorChat}
      />
           <Route
        path="/dashboard/:videoIdItem"
     
        isPrivate
        component={VideosDashboard}
      />
      <Route
        path="/dashboard"
     
        isPrivate
        component={VideosDashboard}
      />

      <Route
        path="/checkin"
  
        isPrivate
 
        component={CheckinQR}
      />  

      <Route template="client" path="/validate/:hash" component={Validate} />

      <Route
        path="/manager/:tab"
        template="manager"
        isSecret
        isPrivate
        component={DashboardPage}
      />

          <Route
        path="/manager"
        template="manager"
        isSecret
        isPrivate
        component={DashboardPage}
      />
    </Switch>
  );
};

export default Routes;
