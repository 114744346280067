import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { uuid } from 'uuidv4';
import { Container, Modal } from './styles';
import { useSocket } from '../../hooks/Socket';
import TextPopper from '../TextPopper';
import Textarea from '../TextareaInput';
import { useToast } from '../../hooks/Toast';
import Button from '../Button';

interface ICreator {
  room_id: number;
  module_id?: number;
}

export const RoomMessagerContainer: React.FC<ICreator> = ({
  room_id,
  module_id = 1,
}) => {
  const { socket } = useSocket();

  const [message, setMessage] = useState<Array<string>>(['']);

  const buildMessage = (string: string, timer = 300) => {
    const { length } = string;

    const stringArray: Array<string> = [];

    const track: Array<string> = [];
    for (let x = 0; x < length; x += 1) {
      const char = string.substr(x, 1);
      stringArray.push(char);
    }

    for (let y = 0; y < timer; y += 1) {
      track.push('');
    }

    setMessage([string]);
  };

  useEffect(() => {
    socket.on('getRoomMessage', state => {
      buildMessage(state.message);
    });
  }, []);

  useEffect(() => {
    buildMessage('');
    socket.emit('requestRoomMessage', { room_id, module_id });
  }, [room_id, module_id]);

  return (
    <Container>
      <TextPopper
        keyName={`key${room_id}`}
        key={uuid()}
        group
        direction="up"
        time={60000}
        messages={message}
      />
    </Container>
  );
};

export const RoomMessagerCreator: React.FC<ICreator> = ({
  room_id,
  module_id = 1,
}) => {
  const { socket } = useSocket();
  const { addToast } = useToast();
  const [creatorActive, setCreatorActive] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handleMessage = data => {
    if (data.message) {
      socket.emit('sendRoomMessage', {
        message: data.message,
        room_id,
        module_id,
      });
      addToast({ type: 'success', title: 'Enviado com sucesso' });
      setCreatorActive(false);
    }
  };

  return (
    <>
      {!creatorActive && (
        <Button onClick={() => setCreatorActive(true)} type="button">
          Alterar Mensagem da Sala
        </Button>
      )}
      {creatorActive && (
        <Modal top right fullHeight>
          <div>
            <button type="button" onClick={() => setCreatorActive(false)}>
              Fechar
{' '}
            </button>
            <Form ref={formRef} onSubmit={handleMessage} initialData={{}}>
              <Textarea name="message" placeholder="Mensagem" />
              <Button type="submit">Enviar</Button>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
};
