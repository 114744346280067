import { createGlobalStyle, keyframes } from 'styled-components';
import { ThemeDefinitions } from './ThemeComponents';

const waveTop = keyframes`
from {

  top: -210px;
  transform: rotateX(0deg);
}
to{

  top: -180px;
  transform: rotateX(30deg);
}
`;

const waveBottom = keyframes`
from {

  bottom: -210px;
  transform: rotateX(0deg);
}
to{

  bottom: -180px;
  transform: rotateX(30deg);

}
`;

export default createGlobalStyle`

@font-face {
    font-family: Montserrat;
    src: url("/assets/fonts/helveticaC.otf") format("opentype");
}


@font-face {
    font-family: MontserratBold;
    src: url("/assets/fonts/helveticaB.otf") format("opentype");
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0
}

html{
  width: 100%;
  min-height: 100vh !important;
 
  background-attachment: fixed;
  background-size: cover;
  background: #087cc5;

  .breakPage {
width: 100%;
height: 1px;
background: #333;
margin: 10px 0;
  }



  @media print {
      background: #fff;
      color: #333;
      height: auto;

.breakPage {
  page-break-after: always;
  width: 0px;
height: 0px;
background: none;
margin:0;

}

    }
 
}

body {

background: url('/assets/Fundo.jpg');
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
  position: relative;

  @media print {
      background: #fff;
      height: auto;
    }

}

#root{
  min-height: 100vh;


 
}

body, input, button{
  font-family: Helvetica,  sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
font-weight: bold;
font-family:  'MontserratBold',Helvetica,  sans-serif;

}

h2{

      font-size: 16px;
}

p, span{
  font-family:'Montserrat',Helvetica,  sans-serif;
}

button{
  cursor: pointer;
}

.wave {

  width: 200%;
  margin-left: -20%;
  z-index:0;

  @media (max-width: 1250px) {
    width: 400%;
    margin-left: -50%;
  }

  @media (max-width: 750px) {
    width: 800%;
    margin-left: -50%;
  }



}

.fixedTop {
 position: fixed;
 top: -210px;
 animation: ${waveTop} 5s infinite alternate-reverse ease;
 left: 0;
}

.fixedBottom{
  position: fixed;
 bottom: -210px;
 animation: ${waveBottom} 5s infinite alternate-reverse;
 left: 0;

}

.computerOnly{
display: flex;
@media (max-width: 850px) {
    display: none;
  }
}

.mobileOnly{
display: none;
@media (max-width: 850px) {
    display: flex;
  }
}

button.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: #ffba1c !important;
      color: #000e7c  !important;
  
      transition: background 0.5s;
      border: 0;
      border-radius: 8px;

      &:hover {
        background: #ffba1c  !important;
      }
    }

    a {
      text-decoration: none !important;
    }


.shadowButton {
  background: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow : 0px 0px 5px rgba(0,0,0,0.3) ;
  padding: 10px;
  margin: 5px;
}
`;
