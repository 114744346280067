import React, { Children, useEffect, useState } from 'react';

import { Container } from './styles';

interface IVideoBackgroundProps {
  slides : Array<string>;
  speed ?: number;
}

const ImageSlider: React.FC<IVideoBackgroundProps> = ({
  slides,
  speed = 20000

}) => {
  const [currentSlide,setCurrentSlide] = useState<string>(slides[0] || '');

  useEffect(() => {
    let x = 0;
    const length = slides.length - 1;

    setInterval(() => {
      x = x +1;
      if(x > length){
        x = 0;
      }

      setCurrentSlide(slides[x]);
    },speed)


  },[slides])

  return (
    <Container background={currentSlide}/>
  );
};

export default ImageSlider;
