import React, { useEffect } from 'react';
import { Wrapper, Background } from './styles';
import { Pattern } from '../../../styles/ThemeComponents';
import Header from '../../../components/Header';
import { Menu } from '../../../components/Menu';
import { SocketProvider } from '../../../hooks/Socket';
import { useAuth } from '../../../hooks/Auth';
import FooterLayout from '../../Footer';
import Particles from 'react-particles-js';
const menuList = [
  {
    title: 'Home',
    target: '/',
    icon: '',
    external: 2,
    fluid: 1,
    targetID: '',
    status : 2
  },
  {
    title: 'Leitor de Checkin',
    target: '/checkin',
    targetID: '',
    fluid: 1,
    icon: '',
    status : 2
  },
  {
    title: 'Webinar',
    target: '/dashboard',
    targetID: '',
    fluid: 1,
    icon: '',
    status : 2
  },
  {
    title: 'Gerenciador',
    target: '/manager',
    icon: '',
    fluid: 1,
    targetID: '',
    status : 2
  },
];

const DefaultLayout: React.FC = ({ children }) => {
  return (
 <>
      <Wrapper>
      <Menu menuList={menuList} fixedMenu={false} />
      <br/>
      {children}
      <FooterLayout/>
     </Wrapper>
     <Particles
        params={{
          particles: {
            lineLinked: {
              enable: false,
            },
            move: {
              speed: 1.5,
              direction: 'top-right',
              collisions: false,
              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },

             
            },
            opacity: {
              value: 0.4,
              random: false,
              animation: {
                enable: false
              }

            },
            shape: {
              type: 'image',
              polygon: {
               
                nb_sides: 6,
                
              },
              image: {
                src: "/apoio/drone.png",
                width: 100,
                height: 100
              }
            },
     
            color: { value: 'rgb(0,100,200)' },

            number: {
              density: {
                enable: true,
                value_area: 2400,
              },
              value: 60,
            },
            size: {
              value: 32,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 1
        }}
      />
      </>

  );
};

export default DefaultLayout;
