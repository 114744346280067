import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
  FiMinus,
  FiPlus,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Container, ContainerWhite, Evento, PhotoMini } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import { program, activities, speakers } from './program';

interface ProgramData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Program: React.FC = () => {
  const [activeProgram, setActiveProgram] = useState(0);
  const [activeEvent, setActiveEvent] = useState([0, -1]);

  const read = (selectedDay, period = 0) => {
    const day = program[selectedDay];

    const readPeriods = (periods, periodKey) => {
      return periods.map((item, index) => {
        const activityIndex = activities.findIndex(actID => actID.id === item);

        return (
          activityIndex >= 0 && (
            <Evento key={`event-${activityIndex}`} active={false}>
              <div
                onClick={() => {
                  periodKey === activeEvent[0] && index === activeEvent[1]
                    ? setActiveEvent([periodKey, -1])
                    : setActiveEvent([periodKey, index]);
                }}
              >
                {' '}
                <div className="evento-horario">
                  {activities[activityIndex].dateText}
                </div>
                <h2 className="evento-title">
                  {' '}
                  {activities[activityIndex].title}
{' '}
                </h2>
              </div>
              {activities[activityIndex].content.length > 0 && (
                <div className="eventoExtra">
                  {activities[activityIndex].content.map(itemContent => {
                    return (
                      <>
                        <h3>{itemContent.title}</h3>
                        <ul>
                          {itemContent.speakers.map(key => {
                            const speakerIndex = speakers.findIndex(
                              itemD => itemD.id === key,
                            );
                            return (
                              speakerIndex >= 0 &&
                              speakers[speakerIndex] && (
                                <li
                                  key={`speaker${speakerIndex}`}
                                  className={
                                    speakers[speakerIndex].className || ''
                                  }
                                >
                                  {speakers[speakerIndex].photo &&
                                    speakers[speakerIndex].photo !== '' && (
                                      <PhotoMini
                                        background={
                                          speakers[speakerIndex].photo
                                        }
                                      >
                                        <div className="arco1" />
                                        <div className="arco2" />
                                      </PhotoMini>
                                    )}
                                  <div className="textoPalestrante">
                                    <strong>
                                      {speakers[speakerIndex].name}
                                    </strong>
{' '}
                                    {speakers[speakerIndex].position && '-'}
{' '}
                                    {speakers[speakerIndex].position}
                                  </div>
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </>
                    );
                  })}
                </div>
              )}
            </Evento>
          )
        );
      });
    };

    return day.periods.map((item, periodIndex) => {
      return (
        <>
          <h2>{item.title} </h2>
          {readPeriods(item.events, periodIndex)}
        </>
      );
    });
  };

  const printSpeaker = key => {
    const speakerIndex = speakers.findIndex(itemD => itemD.id === key);
    return (
      speakerIndex >= 0 &&
      speakers[speakerIndex] && (
        <li
          key={`speaker${speakerIndex}`}
          className={speakers[speakerIndex].className || ''}
        >
          {speakers[speakerIndex].photo && speakers[speakerIndex].photo !== '' && (
            <PhotoMini background={speakers[speakerIndex].photo}>
              <div className="arco1" />
              <div className="arco2" />
            </PhotoMini>
          )}
          <div className="textoPalestrante">
            <strong>{speakers[speakerIndex].name}</strong>
{' '}
            {speakers[speakerIndex].position && '-'}
{' '}
            {speakers[speakerIndex].position}
          </div>
        </li>
      )
    );
  };

  return (
    <ContainerWhite id="programacao">
      <div className="p600 ">
        <h2>PROGRAMAÇÃO</h2>

        <div className="ProgramGroup">
          <button onClick={() => setActiveProgram(0)} type="button">
            {' '}
            <h2>27/08</h2>
            <h4>Quinta-feira</h4>
          </button>
{' '}
        </div>
        <div className="separator" />

        <h2>TARDE</h2>

        <Evento active={false}>
          <div className="evento-horario">13h</div>
          <h2 className="evento-title">INTERAÇÃO AO VIVO COM AS STARTUPS</h2>
        </Evento>

        <h2>NOITE</h2>

        <Evento active={false}>
          <div className="evento-horario">19h</div>
          <h2 className="evento-title">PALESTRAS E APRESENTAÇÕES</h2>

          <div
            className="eventoExtra"
            style={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <div
              style={{ minWidth: '100px', width: '100px', textAlign: 'center' }}
            >
              <PhotoMini
                style={{ marginRight: '10px' }}
                background="/assets/evento/palestrantes/otavio.png"
              >
                <div className="arco1" />
                <div className="arco2" />
              </PhotoMini>
{' '}
            </div>
            <div>
              <h3 style={{ margin: 0 }}>19h</h3>
              <p>
                Palestra Inovação e Agronegócio com Otávio Celidonio, Diretor
                executivo da AgriHub
              </p>

              <ul>{[].map(key => printSpeaker(key))}</ul>
            </div>
          </div>

          <div
            className="eventoExtra"
            style={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <div
              style={{ minWidth: '100px', width: '100px', textAlign: 'center' }}
            >
              <PhotoMini
                style={{ marginRight: '10px' }}
                background="/assets/evento/palestrantes/pitches.png"
              >
                <div className="arco1" />
                <div className="arco2" />
              </PhotoMini>
{' '}
            </div>
            <div>
              <h3 style={{ margin: 0 }}>19h30</h3>
              <p>
                Apresentação de pitches com 7 Startups selecionadas e banca
                avaliadora
              </p>

              <ul>{[].map(key => printSpeaker(key))}</ul>
            </div>
          </div>

          <div
            className="eventoExtra"
            style={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <div
              style={{
                minWidth: '100px',
                width: '100px',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <PhotoMini
                style={{ marginLeft: '-10px' }}
                background="/assets/evento/palestrantes/cesar.png"
              >
                <div className="arco1" />
                <div className="arco2" />
              </PhotoMini>
              <PhotoMini
                style={{
                  marginRight: '10px',
                  position: 'absolute',
                  right: '0%',
                  top: '30%',
                }}
                background="/assets/evento/palestrantes/rogerio.png"
              >
                <div className="arco1" />
                <div className="arco2" />
              </PhotoMini>
{' '}
            </div>
            <div>
              <h3 style={{ margin: 0 }}>21h</h3>
              <p>César Oliveira e Rogério Melo</p>

              <ul>{[].map(key => printSpeaker(key))}</ul>
            </div>
          </div>

          <div
            className="eventoExtra"
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: 0,
              paddingTop: '40px',
            }}
          >
            {' '}
            <div
              style={{ minWidth: '100px', width: '100px', textAlign: 'center' }}
            >
              <PhotoMini
                style={{ marginRight: '10px' }}
                background="/assets/evento/palestrantes/andre.png"
              >
                <div className="arco1" />
                <div className="arco2" />
              </PhotoMini>
{' '}
            </div>
            <div>
              <h3 style={{ margin: 0 }}>Mediadores</h3>
              <p>
                <strong>André Bordignon</strong>
{' '}
<br />
                Sebrae RS
              </p>

              <ul>{[].map(key => printSpeaker(key))}</ul>
            </div>
          </div>

          <div
            className="eventoExtra"
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: 0,
            }}
          >
            {' '}
            <div
              style={{ minWidth: '100px', width: '100px', textAlign: 'center' }}
            >
              <PhotoMini
                style={{ marginRight: '10px' }}
                background="/assets/evento/palestrantes/cibele.png"
              >
                <div className="arco1" />
                <div className="arco2" />
              </PhotoMini>
{' '}
            </div>
            <div>
              <h3 style={{ margin: 0 }} />
              <p>
                <strong>Cibele Bolzan Scherer</strong>
{' '}
<br />
                Sebrae Campanha e Fronteira Oeste
              </p>

              <ul>{[].map(key => printSpeaker(key))}</ul>
            </div>
          </div>
        </Evento>
      </div>
    </ContainerWhite>
  );
};
export default Program;
