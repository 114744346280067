import React, { useCallback, useRef, useEffect, useState } from 'react';

import {program} from './programacao'; 


import { IconType } from 'react-icons/lib';
import api from '../../services/api';
import {date,hour} from '../../utils/date';

import {Ballom,ContentDiv,DateDiv,ImgDiv, Line, HeadLine,Container,ContainerButton} from './styles';
import Input from '../../components/Input';
import { Form } from '@unform/web';
import Button from '../../components/Button';

interface ProgramData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}



interface IActivity {
  room_name : string;
  room_color: string;
  date_text : string;
  title : string;
  subtitle:  string;
  description : string;
  photos : Array<string>;
}

interface IProgram {
  title : string;
    date: string;
    description : string;
    image : string;
    day: string;
    city: string;
    activity : Array<IActivity>;
}

const Program: React.FC = () => {
  const [programs, setPrograms] = useState<Array<IProgram>>(program);
  const [cityList, setCityList] = useState<Array<string>>([]);
  const [titleList, setTitleList] = useState<Array<string>>([]);
  const [dayList, setDayList] = useState<Array<string>>([]);
  const [currentCity,setCurrentCity] = useState('');
  const [currentTitle,setCurrentTitle] = useState('PASSAPORTE INSIGHT DIGITAL');
  const [currentSearch,setCurrentSearch] = useState('');
  const [currentDay,setCurrentDay] = useState('');
  const [filterList, setFilterList] = useState({
    search : '',
    title : '',
    city: '',
    day : ''
  });

  const filter = (column,content) => {

    const newPrograms = program.filter( p => p[column] === content);

    const getFilter = {...filterList};

    getFilter[column] = getFilter[column] === content ? '' : content;
    if(column === 'search'){ setCurrentSearch( content);}
    if(column === 'title'){ setCurrentTitle(state => state === content ? '' : content);}
    if(column === 'day'){ setCurrentDay(state => state === content ? '' : content);}
    if(column === 'city'){ setCurrentCity(state => state === content ? '' : content); }

    setFilterList(getFilter);

  }

  useEffect(() => {

    let newPrograms : Array<IProgram> = program;

    if(filterList.title !== ''){
      newPrograms = newPrograms.filter( p => p.title === filterList.title);
    }

    if(filterList.city !== ''){
      newPrograms = newPrograms.filter( p => p.city === filterList.city);
    }

    if(filterList.day !== ''){
      newPrograms = newPrograms.filter( p => p.day === filterList.day);
    }

    if(filterList.search !== ''){

      const newFilter : Array<IProgram> = [];
      const reference = filterList?.search?.toString()?.trim()?.toLowerCase();
      
      newPrograms.map( (newp) => {
        const activities : Array<IActivity> = [];
       newp.activity.map( a => {
          const title = a?.title?.toString()?.trim()?.toLowerCase();
          const date_text = a?.date_text?.toString()?.trim()?.toLowerCase();
          const subtitle = a?.subtitle?.toString()?.trim()?.toLowerCase();

          if(title.indexOf(reference) >=0 || date_text.indexOf(reference) >=0 || subtitle.indexOf(reference) >=0){
            activities.push(a);
          }

        })

        if(activities.length > 0){
          newFilter.push({...newp,activity : activities})
          
        }


      })
      setPrograms(newFilter);
      return;
      
    }

    setPrograms(newPrograms);

  },[filterList])


  const line = (program) => {

    return (<tr>
    <td></td>
    <td>{program.date}</td>
    <td>{program.hour}</td>
    <td>{program.category}</td>
    <td>{program.title}</td>
    <td></td>
  </tr>);

  }

  useEffect(() => {

    const cities : Array<string> = [];
    const days : Array<string> = [];
    const hubs : Array<string> = [];

program.map( city => {
  const index = cities.findIndex(c => c === city!.city);

  if(index < 0){
    cities.push(city!.city);
  }

  const indexT = hubs.findIndex(c => c === city!.title);

  if(indexT < 0){
    hubs.push(city!.title);
  }

  const indexD = days.findIndex(c => c === city.day);

  if(indexD < 0){
    days.push(city!.day);
  }
})
setCityList(cities);
setDayList(days);
setTitleList(hubs);
  },[program])

  const addSearch = ({search}) => {
    filter('search',search)

  }

  return (
    <>

  <ContainerButton >
  <div className="buttonProgram" onClick={() => filter('title','PASSAPORTE INSIGHT DIGITAL')} >
      <img style={{borderColor : currentTitle === '' ? '#ec6607 ': '#fff'}} src="/botao/botao_programação_passaporte.jpg"/>
    </div>
    <div className="buttonProgram" onClick={() => filter('title','Insight House')} >
      <img style={{borderColor : currentTitle === 'Insight House' ? '#ec6607 ': '#fff'}} src="/botao/botao_programacao_house_pelotas.jpg"/>
    </div>
    <div className="buttonProgram" onClick={() => filter('title','Hub Pelotas Parque Tecnológico')} >
      <img style={{borderColor : currentTitle === 'Hub Pelotas Parque Tecnológico' ? '#ec6607 ': '#fff'}} src="/botao/botao_programacao_hub pelotas.jpg"/>
    </div>
    <div className="buttonProgram" onClick={() => filter('title','Hub Rio Grande')} >
      <img style={{borderColor : currentTitle === 'Hub Rio Grande' ? '#ec6607 ': '#fff'}} src="/botao/botao_programacao_hub_riogrande.jpg"/>
    </div> 
    <div className="buttonProgram" onClick={() => filter('title','Hub dos Vales')} >
      <img style={{borderColor : currentTitle === 'Hub dos Vales' ? '#ec6607 ': '#fff'}} src="/botao/botao_programacao_hub_vales.jpg"/>
    </div>
    <div className="buttonProgram" onClick={() => filter('title','Palco Temporada Gastronômica')} >
      <img style={{borderColor : currentTitle === 'Palco Temporada Gastronômica' ? '#ec6607 ': '#fff'}} src="/apoio/temporadagastronomica.png"/>
    </div>
  </ContainerButton>

  <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
    <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
      <h2 style={{margin: '5px 20px', fontSize: '24px'}}>Palco</h2> 
    <select style={{padding: '10px', minHeight: '56px'}} onChange={(e) => filter('title',e?.target?.value)} name="palco">
      <option selected={currentTitle === ''} value="">Todos</option>
      <option selected={currentTitle === 'PASSAPORTE INSIGHT DIGITAL'} value="PASSAPORTE INSIGHT DIGITAL">Passaporte Insight Digital</option>
      <option selected={currentTitle === 'Insight House'} value="Insight House">Insight House</option>
      <option selected={currentTitle === 'Hub Pelotas Parque Tecnológico'} value="Hub Pelotas Parque Tecnológico">Hub Pelotas Parque Tecnológico</option>
      <option selected={currentTitle === 'Hub Rio Grande'} value="Hub Rio Grande">Hub Rio Grande</option>
      <option selected={currentTitle === 'Hub dos Vales'} value="Hub dos Vales">Hub dos Vales</option>
      <option selected={currentTitle === 'Palco Temporada Gastronômica'} value="Palco Temporada Gastronômica">Palco Temporada Gastronômica</option>
      </select>
      </div>
      <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
      
      <Form onSubmit={addSearch} style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Input style={{maxWidth: '200px',height: '56px', margin: '5px', padding: '10px'}} placeholder="Pesquisar..." name="search"/>
        <Button style={{margin: '0px'}} type="submit">Pesquisar</Button>
      </Form>
      
      </div>


  </div>

 {
      


      programs.map( stage => {
return <Container>
        <HeadLine>
          <h2>{stage.title}{' '}{stage.date}</h2>
          <br/>
          <p dangerouslySetInnerHTML={{__html: stage.description}} />
       </HeadLine>
       
       {stage.activity.map( act => {
return (
         <Line>
          {act.room_name && <Ballom style={{background: act.room_color}}>{act.room_name}</Ballom> }
           <div style={{display:'flex'}}>
           <div>
            
       
          
           <DateDiv>{act.date_text}</DateDiv> 
           </div>
           <div>
             
            <ImgDiv></ImgDiv>
            <ContentDiv>
          
            <h2 dangerouslySetInnerHTML={{__html: act.title}} />
              <p dangerouslySetInnerHTML={{__html: act.subtitle}} />
              <p dangerouslySetInnerHTML={{__html: act.description}} />
             
            </ContentDiv>
           </div>
           </div>
         </Line>)
       })}


        </Container>

      })
    }
     
    </>
  );
};
export default Program;
