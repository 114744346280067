import styled, { keyframes,css } from 'styled-components';

interface IBackground {
  background?: string;
}

export const Container = styled.div<IBackground>`
  min-height: auto;

  display: flex;
  background: none;
  color: #fff;
  padding: 10px;
  width: 100%;
  max-width: 100%;


  @media (max-width: 750px) {
    flex-direction: column;
  }

  > h2 {
    display: flex;
    justify-content: center;
  }

  .miniPrograma {
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    > figure {
      max-width: 100%;
      margin: 5px 0;
      width: 100%;
      > table {
        width: 100%;
        border-collapse: collapse;
        max-width: 100%;
        > tbody {
          max-width: 100%;
          > tr {
            border: 1px solid #ddd;
            > td {
              font-size: 10px;
              font-weight: 400;
              padding: 3px;
              min-width: 100px;
            }
            > td + td {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .programDayHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    > span {
      min-width: 36px;
    }

    > h2 {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 750px) {
    padding: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;

    > tbody {
      > tr {
        border-top: 1px solid #555;

        > td {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 5px 0;
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            > a > {
              button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
`;

export const Sidebar = styled.div`
min-width: 300px;
width: 300px;
padding: 15px;
>div{
  margin: 5px;
}
label{
  color: #333;
  
}
`;

interface IPropsBody {
  size: number;
  responsive : string;
}

export const Body = styled.div<IPropsBody>`
overflow-x: auto;
background: #fff;
padding: 20px;
width: 100%;
max-width: 100%;
  ${props => props.size && props.responsive === 'Não' && css`
  >div{
  min-width: ${40*props.size}px;
  max-width: auto;
  }
  `}
`;