import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { uuid } from 'uuidv4';
import { RiFileExcel2Line } from 'react-icons/ri';
import {
  FaChevronLeft,
  FaArrowLeft,
  FaChevronRight,
  FaArrowRight,
} from 'react-icons/fa';
import Input from '../Input';
import MaskedInput from '../MaskedInput';
import Button from '../Button';
import api from '../../services/api';
import { Container, Body, Header, Navigation, Field } from './styles';
import mask from '../../utils/mask';
import tableRender from './templates/TableRender';
import { useSearch } from '../../hooks/Search';
import SelectApi from '../SelectApi';

/** Gerar uma estrutura pesquisável */

interface IObject {
  [key: string]: string;
}

export interface ISearchComponent {
  idKey: string;
  title?: string;
  endpoint: string;
  schema: Record<string, Record<string, any>>;
  autoStart?: boolean;
  headModules?: Array<Function>;
  bodyModules?: Array<Function>;
  renderFunction?: Function;
}

export function SearchComponent({
  idKey,
  title = '',
  endpoint,
  schema,
  autoStart = false,
  headModules = [],
  bodyModules = [],
  renderFunction = tableRender,
}: ISearchComponent): JSX.Element {
  /*
  const [schemaLayer, setSchemaLayer] = useState(schema);
  const [dataRows, setDataRows] = useState([]);

  const apiRequest = async (
    text: string,
    order: Record<string, any>,
  ): Promise<void> => {
    const params = { search: text || '', order };
    const response = await api.get(endpoint, { params });

    const display = response.data.reduce(
      (prev: Array<Record<string, any>>, items: IObject) => {
        const elem: IObject = {};
        Object.keys(schema).map(key => {
          elem[schema[key].column] = schema[key].mask
            ? mask(items[schema[key].column], schema[key].mask || '')
            : items[schema[key].column];
        });

        prev = [...prev, elem];
        return prev;
      },
      [],
    );

    setDataRows(display);
  };

  useEffect(() => {
    const load = async (): Promise<void> => {
      if (autoStart === true) {
        await apiRequest('', []);
      }
    };
    load();
  }, [autoStart]);

  const handleSearch = async (data: Record<string, any>): Promise<void> => {
    await apiRequest(data.search, []);
  };
  */
  const [startAuto, setStartAuto] = useState(false);

  const {
    addSearch,
    reloadSearch,
    changeSearchText,
    dataRows,

    changePage,
    paginations,
    containers,
  } = useSearch();

  useEffect(() => {
    addSearch(idKey, { id: idKey, endpoint, schema });
    if (autoStart) {
      setStartAuto(true);
    }
  }, []);

  useEffect(() => {
    if (startAuto === true) {
      reloadSearch(idKey);
    }
  }, [startAuto]);

  const handleSearch = async (data: Record<string, any>): Promise<void> => {
    await changeSearchText(idKey, data.search);
  };

  const addToWhere = (key, column, value) => {

    const container = containers.find(cont => cont.id === key);
    if (container) {
      const where = container.where ? { ...container.where } : {};
      if (value === '0' || value === 0) {
        if (where[column]) {
          delete where[column];
        }
      } else {
        where[column] = value;
      }

      container.where = { ...where };
    }
    reloadSearch(idKey);
  };

  const renderFilter = (): JSX.Element => {
    return (
      <Form onSubmit={() => {}} style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.keys(schema).map(key => {
          if (schema[key].filter && schema[key].filter === true) {
            return (
              <Field style={{ minWidth: '200px', margin: '5px' }}>
                <label style={{ color: '#333' }}>{schema[key].label}</label>
                <SelectApi
                  alias={key}
                  addEmpty
                  endpoint={endpoint}
                  label={schema[key].label}
                  name={key}
                  setCallback={value => addToWhere(idKey, key, value)}
                />
              </Field>
            );
          }
        })}
      </Form>
    );
  };

  const renderPagination = () => {
    const items: Array<JSX.Element> = [];

    const container = paginations[idKey];
    if (container) {
      const { currentPage = 1, pages = 1, countItems = 0 } = container;

      items.push(<span>
{' '}
{countItems || '0'} registros</span>);
      if (currentPage > 2) {
        items.push(
          <button type="button" onClick={() => changePage(idKey, 1)}>
            <FaChevronLeft />
          </button>,
        );
      }

      if (currentPage > 1) {
        items.push(
          <button
            type="button"
            onClick={() => changePage(idKey, currentPage - 1)}
          >
            <FaArrowLeft />
          </button>,
        );
      }

      for (
        let x = currentPage > 1 ? currentPage - 1 : currentPage;
        x <= pages && x <= currentPage + 3;
        x += 1
      ) {
        items.push(
          <button
            type="button"
            className={currentPage === x ? 'active' : ''}
            onClick={() => changePage(idKey, x)}
          >
            {x}
          </button>,
        );
      }

      if (currentPage < pages) {
        items.push(
          <button
            type="button"
            onClick={() => changePage(idKey, currentPage + 1)}
          >
            <FaArrowRight />
          </button>,
        );
      }

      if (currentPage < pages) {
        items.push(
          <button type="button" onClick={() => changePage(idKey, pages)}>
            <FaChevronRight />
          </button>,
        );
      }
    }
    return items;
  };

  const render = (): JSX.Element => {
    return (
      <Container>
        <Header>
          {title ? <h2>{title}</h2> : ''}

          <div>
            <Form onSubmit={handleSearch}>
              {headModules.map(headModule => headModule())}
              <Input
                style={{ borderRadius: '0', minWidth: '200px' }}
                name="search"
                placeholder="Pesquise Aqui"
                type="text"
              />

              <button type="submit">Pesquisar</button>
              <button
                style={{ background: 'rgb(0,100,0)' }}
                type="button"
                onClick={() => reloadSearch(idKey, 'xlsx')}
              >
                <RiFileExcel2Line />
              </button>
            </Form>
          </div>
        </Header>
        {renderFilter()}
        <Navigation>{renderPagination()}</Navigation>
        <Body>
          {renderFunction(idKey, dataRows[idKey], schema, bodyModules)}
        </Body>
      </Container>
    );
  };

  return render();
}
