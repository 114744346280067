import styled from 'styled-components';
import {ContainerRef} from '../styles';

export const Container = styled(ContainerRef)`

>div{
    margin-top: 10px;
    
}

.ck-editor__editable{
    min-height: 300px;
}
`;