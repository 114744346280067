import React from 'react';

import { Container } from './styles';
import modal from './Modal';
import { IModalMessage } from '../../hooks/Modal';

interface IModalContainerProps {
  messages: IModalMessage[];
  className ?: string;
}

const ModalContainer: React.FC<IModalContainerProps> = ({ messages, className = ''}) => {
  return (
    <Container>
      {messages.map(item =>
        modal({ key: item.id, title: item.title, content: item.content, className }),
      )}
    </Container>
  );
};

export default ModalContainer;
