import React, { useState, useEffect } from 'react';

import {
  FaPlus,
  FaTrash,
  FaQuestion,
  FaList,
  FaChartPie,
  FaEye,
} from 'react-icons/fa';
import { FiX, FiSend } from 'react-icons/fi';
import { Container, Field, Modal, QuizzHeader } from './styles';
import { useModal } from '../../hooks/Modal';
import { useToast } from '../../hooks/Toast';
import { logo } from '../../config';
import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';

import { useSocket } from '../../hooks/Socket';

interface IID {
  roomNumber: number;
}

interface IAnswers {
  name: string;
  value?: string;
  label?: string;
}

interface IQuestions {
  id?: string;
  title: string;
  type: number;
  questions: Array<Record<string, any>>;
}

const PopupComponent: React.FC<IID> = ({ roomNumber = 0 }) => {
  const { socket } = useSocket();

  const [value, setValue] = useState({
    title: '',
    message: '',
    room_id: roomNumber,
    button: '',
    button_url: '',
  });

  const [activePopup, setActivePopup] = useState(false);
  const [popup, setPopup] = useState(<> </>);

  const [active, setActive] = useState(false);
  const { addModal } = useModal();

  /* EXCLUI UMA ENQUETE */

  const read = async item => {
    console.log(item);
    setPopup(
      <div style={{ textAlign: 'center' }}>
        {item.title && <h2>{item.title}</h2>}
        {item.message && (
          <p dangerouslySetInnerHTML={{ __html: item.message }} />
        )}
        {item.button && item.button_url && (
          <a href={item.button_url}>
            <button
              style={{ margin: '10px auto' }}
              type="button"
              className="defaultButton"
            >
              {item.button}
            </button>
          </a>
        )}
      </div>,
    );

    setActivePopup(true);
  };

  const send = async () => {
    const data = {
      ...value,
    };
    socket.emit('sendPopup', data);
  };

  const changeValue = (target, text) => {
    const newValue = { ...value };
    newValue[target] = text;
    setValue({ ...newValue });
  };

  useEffect(() => {
    socket.on('showPopup', data => {
      if (data) {
        read(data);
      }
    });
  }, []);

  useEffect(() => {
    changeValue('room_id', roomNumber);
  }, [roomNumber]);

  return (
    <Container>
      <button
        className="defaultButton"
        type="button"
        onClick={() => setActive(true)}
      >
        Criar Popup
      </button>
      {active && (
        <Modal style={{ justifyContent: 'flex-start', paddingTop: '30px' }}>
          <div>
            <h2>Criar Popup</h2>
            <Field>
              <input
                onChange={e => changeValue('room_id', e.target.value)}
                value={value.room_id}
                placeholder="Sala"
              />
            </Field>
            <Field>
              <label>Título</label>
              <textarea
                onChange={e => changeValue('title', e.target.value)}
                value={value.title}
                placeholder="Pergunta"
              />
            </Field>
            <Field>
              <label>Mensagem</label>
              <textarea
                onChange={e => changeValue('message', e.target.value)}
                value={value.message}
                placeholder="Mensagem"
              />
            </Field>

            <Field>
              <label>Texto do Botão</label>
              <input
                onChange={e => changeValue('button', e.target.value)}
                value={value.button}
                placeholder="Texto do botão"
              />
            </Field>

            <Field>
              <label>Link do Botão</label>
              <input
                onChange={e => changeValue('button_url', e.target.value)}
                value={value.button_url}
                placeholder="Texto do botão"
              />
            </Field>

            <button type="button" onClick={() => read(value)}>
              Visualizar
            </button>

            <button type="button" onClick={() => send()}>
              Enviar
            </button>
          </div>
          <button
            className="closeButton"
            type="button"
            onClick={() => setActive(false)}
          >
            Fechar
          </button>
        </Modal>
      )}

      {activePopup && (
        <Modal
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',

            zIndex: 1000000000,
            paddingTop: '30px',
          }}
        >
          {popup}
          <button
            className="closeButton"
            type="button"
            onClick={() => setActivePopup(false)}
          >
            Fechar
          </button>
        </Modal>
      )}
    </Container>
  );
};

export default PopupComponent;
