import styled from 'styled-components';
import pattern from '../../assets/pattern.png';

export const Pattern = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: none;
  background-image: url(${pattern});
  background-repeat: repeat;
  opacity: 0.5;
  z-index: -1;
`;

interface ThemeDefinitionsProps {
  bodyBackground: string;
  bodyTextBackground: string;
  bodyBackgroundWithOpacity: string;
  input: Record<string, any>;
  selectButton: Record<string, any>;
  button: Record<string, any>;
}

export const ThemeDefinitions: ThemeDefinitionsProps = {
  bodyBackground: '#333',
  bodyBackgroundWithOpacity: 'none',
  bodyTextBackground: '#FFF',
  input: {
    placeholder: '#ccc',
    background: '#555',
    color: 'white',
    border: '2px solid #555',
    colorFilled: '#ff9000',
    borderColorFilled: '#ff9000',
    colorFocused: '#ff9000',
    borderColorFocused: '#ff9000',
  },
  selectButton : {
    background : 'rgb(0,50,150)',
    borderColor:  'rgb(0,30,100)',
    color: '#fff',
},
button : {
  background : 'rgb(0,100,0)',
  color: '#fff',
  backgroundHover: 'rgb(0,150,0)',
  colorHover: '#fff',
}
};

export default ThemeDefinitions;
