import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { background } from '../../config';
import { ThemeDefinitions } from '../../styles/ThemeComponents';

const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;
export const Container = styled.div`
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

export const AnimatedDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;
  padding: 20px 0px;
  animation: ${fadeFromRight} 1s;

  form {
    margin: 30px 0;
    width: 100%;
    max-width: 100%;
    text-align: center;

    @media (max-width: 750px) {
      padding: 0px 20px;
      width: 100%;
      max-width: 100%;
    }

    h2 {
      margin: 30px 0;
      color: ${'#fff'};
      font-size: 42px;

    }

    a {
      color: ${'#fff'};
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#fff')};
      }
    }
  }

  > a {
    color: #fff;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;

  z-index: 1;

  @media (max-width: 750px) {
    width: 100%;
  }

  img {
    width: 300px;
    max-width: 100%;
    margin: 10px;


  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url(${background}) no-repeat top left;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: 0;
`;
