const program = { 
    title : 'Insight House',
    date: '10/09 sexta-feira',
    description : `Av. Dom Joaquim, 1087 – Pelotas/RS<br/>
    A “casa” do Insight Sul receberá palestrantes e convidados para estimular os debates e aprendizados que acontecerão nos demais espaços. O espaço anfitrião receberá a programação transmitida para os hubs com experiências, negócios, conhecimento, networking e interação. O Insight House contará com o espaço Like a Boss para startups, painéis gastronômicos exclusivos e cases do Histórias que Inspiram. 
    `,
    image : '/botao/botao_programacao_house_pelotas.png',
    day: '10',
    city: 'Pelotas',
    activity : [

        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '08:00 às 09:00',
            title : 'Credenciamento',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '09:00 às 09:50',
            title : 'Palestra de Abertura - Pedro Englert (CEO da Startse)',
            subtitle: 'Os 7 Fundamentos da Nova Economia ',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '10:00 às 10:50',
            title : 'José Galló - Presidente do Conselho de Administração das  Lojas Renner/SA',
            subtitle: 'O poder da inovação: porque encantar o seu cliente.',
            description : '',
            photos : []
        }, 
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '11:00 às 11:50',
            title : 'Painel Histórias que Inspiram - SAÚDE - Agregação de Valor em serviços tradicionais',
            subtitle: 'Painelistas: Dr. Franklin Correa Barcellos e Dr. Rodrigo Proto Siqueira - Mediação: Ana Paula Rezende',
            description : '',
            photos : []
        },  

        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '12:00 às 13:50',
            title : 'INTERVALO',
            subtitle: '',
            description : '',
            photos : []
        },  
        
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '14:00 às 14:50',
            title : 'Painel Cidades do Futuro com os prefeitos Paula Mascarenhas (Pelotas), Fábio Branco (Rio Grande) e  Marcelo Caumo (Lajeado)',
            subtitle: 'Mediação: Fernando Estima - Superintendente do Porto do Rio Grande do RS',
            description : '',
            photos : []
        },      
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '15:00 às 15:50',
            title : 'Painel Histórias que Inspiram - TURISMO - Um propósito de vida e de negócios',
            subtitle: 'Painelistas: Pedro Vieira Bastos e Vanda Maders Schneider -  Mediação: Amanda Paim',
            description : '',
            photos : []
        },         
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '16:00 às 16:50',
            title : 'Tito Gusmão - CEO e Fundador da Warren',
            subtitle: 'Startup Raiz: A jornada de empreender',
            description : '',
            photos : []
        },     
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '17:00 às 17:50',
            title : 'Daniel Randon CEO da Randon S.A. e Presidente do Conselho Superior do TransformaRS',
            subtitle: 'Inovação - Novas Oportunidades para o crescimento sustentável dos Negócios',
            description : '',
            photos : []
        },  
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '18:00',
            title : 'Encerramento',
            subtitle: '',
            description : '',
            photos : []
        }, 

 
        {
            room_name : 'Palco Like a Boss', room_color: '#89398f',
            date_text : '10:30 às 11:50',
            title : 'Round 1 com 6 startups',
            subtitle: '',
            description : '',
            photos : []
        },     
        {
            room_name : 'Palco Like a Boss', room_color: '#89398f',
            date_text : '12:00 às 14:00',
            title : 'Intervalo',
            subtitle: '',
            description : '',
            photos : []
        }, 
        {
            room_name : 'Palco Like a Boss', room_color: '#89398f',
            date_text : '14:00 às 14:50',
            title : 'Talk',
            subtitle: '',
            description : '',
            photos : []
        }, 

        {
            room_name : 'Palco Like a Boss', room_color: '#89398f',
            date_text : '15:00 às 16:20',
            title : 'Round 2 com 6 startups',
            subtitle: '',
            description : '',
            photos : []
        },      
        {
            room_name : 'Palco Like a Boss', room_color: '#89398f',
            date_text : '16:30 às 17:00',
            title : 'Painel Startups - AGS, Comunidade RS e Sebrae',
            subtitle: '',
            description : '',
            photos : []
        },    
        {
            room_name : 'Atrações', room_color: 'rgb(0, 0, 100)',
            date_text : '',
            title : `
            Mostra de 10 Startups: <br/>
            <br/>
            Connectbe contabilidade pró-ativa<br/>
            vouconstruir.net<br/>
            Nuinset<br/>
            Partamon<br/>
            Dream Tech - Casas Conectadas<br/>
            Green Next<br/>
            AkiDelivery<br/>
            Criação .cc<br/>
            Anota ai<br/>
            Captur Investimentos<br/><br/>
            Talks exclusivos`,
            subtitle: '',
            description : '',
            photos : []
        },


    ]

}

export default program;