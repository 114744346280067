import { format, parseISO, addHours, getHours} from 'date-fns';
import {utcToZonedTime } from 'date-fns-tz';
import pt from 'date-fns/locale/pt-BR/index.js';

export const date = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

      const zonedDate = utcToZonedTime(dateValue, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, 'dd/MM/yyyy', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const toPTBr = (dateValue: string): string => {
  try{
  let parsedDate =
    typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
  parsedDate = addHours(parsedDate,-3);
  return parsedDate.toISOString();
  }
  catch(err) {
    return ''
  }
};

export const dateSimple = (dateValue: string): string => {
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
      const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, 'dd/MM', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const hour = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
     

      const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "HH'h'mm", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const dateLanguage = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
      const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd 'de' MMMM '-' EEEE", {
      locale: pt,
    });
  } catch (err) {
    return '';
  }
  return formattedDate;
};
export const simpleDate = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
      const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd'.'MM", {
      locale: pt,
    });
  } catch (err) {
    return '';
  }
  return formattedDate;
};
