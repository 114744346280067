import React, { useEffect } from 'react';
import { Wrapper, Background, Footer } from './styles';
import { Pattern } from '../../../styles/ThemeComponents';
import Header from '../../../components/Header';
import { Menu } from '../../../components/Menu';
import { SocketProvider } from '../../../hooks/Socket';
import { useAuth } from '../../../hooks/Auth';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';


const DefaultLayout: React.FC = ({ children }) => {
  const {menu} = usePages();
  return (
    <SocketProvider>
      <Wrapper>
        <Menu menuList={menu} fixedMenu={false} />
        {children}

      </Wrapper>
  <FooterLayout/>
    </SocketProvider>
  );
};

export default DefaultLayout;
