import styled, { keyframes } from 'styled-components';

const fade = keyframes`
from {
  opacity: 0;

  filter: blur(4px);
}
to{
  opacity: 1;

  filter: blur(0px);
}`;

const fadeFromBottom = keyframes`
from {
  opacity: 0;
  transform: translateY(20px);
  filter: blur(4px);
}
to{
  opacity: 1;
  transform:translateX(0);
  filter: blur(0px);
}
`;
const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(20px);
  filter: blur(4px);
}
to{
  opacity: 1;
  transform:translateX(0);
  filter: blur(0px);
}
`;
const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(-20px);
  filter: blur(4px);
}
to{
  opacity: 1;
  transform:translateX(0);
  filter: blur(0px);
}
`;
const fadeFromTop = keyframes`
from {
  opacity: 0;
  transform: translateY(-20px);
  filter: blur(4px);
}
to{
  opacity: 1;
  transform:translateX(0);
  filter: blur(0px);
}
`;

const toLeft = keyframes`
from {
  margin-right:0;
}
to{
  margin-right: 100%
}
`;

interface IAnime {
  direction?: 'up' | 'down' | 'left' | 'right' | 'fade';
}

const validAnimation = {
  up: fadeFromTop,
  down: fadeFromBottom,
  left: fadeFromLeft,
  right: fadeFromRight,
  fade,
};

export const AnimatedDiv = styled.div<IAnime>`
  margin-right: 0;
  transition: 60s all;
  animation: ${toLeft} 40s infinite;
  display: inline-block;
  width: auto;
`;
