import styled, { keyframes } from 'styled-components';

const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;
export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 30px 10%;
  background: #ddd;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  background: none;
  color: #333;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;

    form {
      display: flex;

      > input {
        border: 0;
        padding: 20px;
        background: #eee;
        color: #333;
      }
      > button {
        border: 0;
        padding: 20px;
        background: rgb(0, 100, 150);
        color: #fff;
      }
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;

  > table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    table-layout: auto;
    background: white;
    margin-top: 10px;

    tr {
      td,
      th {
        border: 1px solid #ddd;
        text-align: left;
        color: #333;
        padding: 5px;
      }
    }

    tr + tr {
      border-top: 1px solid #999;
    }
  }
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  background: #ffffff;
  width: 100%;

  > button {
    width: 30px;
    min-width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: none;
    border: 1px solid #333;
    color: #333;
    font-size: 14px;
    margin: 3px;
  }

  > button.active {
    background: rgb(0, 100, 150);
    color: #ffffff;
    border-color: rgb(0, 100, 150);
  }

  > span {
    padding: 0px 10px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: 1px solid #333;
    color: #333;
    font-size: 14px;
    margin: 3px;
  }
`;

export const Field = styled.div`
  margin-top: 10px;
  > label {
    margin-bottom: 5px;
    display: flex;
  }
`;
