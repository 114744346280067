// export const gateKey = '';
export const hash = '14e1412f-0f1e-11ec-9ec1-1684972fa0df';
export const gateKey = '';
export const recaptchaKey = '6LfjI50bAAAAAP55lmUDtlSFLrG1iA0xzvRzup_1';
export const apiUrl = `https://api-global.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://global-socket.encontrodigital.com.br/';
//export const apiSocket = 'http://localhost:8001';
//export const apiUrl = `http://localhost:8000/${hash}`;
export const urlWebsite = 'https://insight.sebraers.com.br';

export const home = 'Home';
// export const urlWebsite = 'http://localhost:3000';
export const eventName = 'Insight Sebrae RS';
export const logo = '/assets/evento/logo.png';
export const background = '/assets/evento/Fundo.jpeg';
export const authTitle = 'Insight Sebrae RS';

export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  twitter: '',
};
export const supportNumber = '5551981476007';
export const dadosOrganizacao = ``

export const defaultTheme = {
  defaultBackground: '#3444a3',
  defaultColor: '#fff',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: 'none',
    backgroundSticky: 'none',
    backgroundMobile: '#3444a3',
    mobileColor: '#fff',
    color: '#fff',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};
