const program = { title : 'Palco Temporada Gastronômica',
    date : '10/09 sexta-feira',
    city: 'Plataforma online',
    description : ``,
    day: '10',
    image : '',
    activity : [
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '09:00 às 10:00',
            title : 'O Futuro é Glocal - Painelistas: Gustavo Albuquere – Wilianm & Sons, Rafael Borges - 4 Islând e Nilo Schiavon - Produtor de Orgânicos',
            subtitle: 'Mediador Paulo Renato Ardenghi Rizzardi',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '10:15 às 11:15',
            title : 'Restaurantes e o Futuro - Nico Ventre - Eat Kitchen, Chef de Cozinha (a confirmar), Gabriel Oliveira - Confraria do Espetinho/Mordomia/FoodHall',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '12:00 às 13:50',
            title : 'INTERVALO',
            subtitle: '',
            description : '',
            photos : []
        },        
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '14:00 às 15:00',
            title : 'Mercados ou Comunidades? Como estão sendo construídos os novos negócios para venda de comida - Gabriel Drumond - Mercado Brasco, Manoela Bertaso - FOOD HALL Dado Bier e Cristina Kath - Viva Grano ',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '15:10 às 16:10',
            title : 'Que o Futuro Seja Doce! - Tiago Schimitz - Charlie Brownie, Marcelo Gonçalves – Pâtissier e Muriel Duarte Sevastakiev - Delícias Portuguesas',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '17:30 às 18:30',
            title : 'Startups utilizando tecnologia e Inovação, elas estão reinventando o futuro - Painelistas: Flor Oliveira - Veond (Startup Portuguesa para veganos), Frederico - FABA (Foodtech), Diego Fabris - Wine Locals - Mediação Ana Berger - Cumbuca Food Hub',
            subtitle: '',
            description : '',
            photos : []
        },
    ]

}

export default program;