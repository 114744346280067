
import insight_10 from './programdays/insight_10';

import pelotas_10 from './programdays/pelotas_10';
import riogrande_10 from './programdays/riogrande_10';
import vales_10 from './programdays/vales_10';


import insight_11 from './programdays/insight_11';

import pelotas_11 from './programdays/pelotas_11';
import riogrande_11 from './programdays/riogrande_11';
import vales_11 from './programdays/vales_11';
import passaporte from './programdays/passaporte';
import gastronomico from './programdays/gastronomico_10';
export const program = [
    passaporte,
    insight_10,
    pelotas_10,
    riogrande_10,
    vales_10,
    gastronomico,
    insight_11,
    pelotas_11,
    riogrande_11,
    vales_11
];