interface ISpeaker {
  id: number;
  name: string;
  company: string;
  position: string;
  category: number;
  photo: string;
  className?: string;
  activities: Array<number>;
  description ?: string;
}

const period = [
  { id: 1, title: 'Manhã' },
  { id: 2, title: 'Tarde' },
  { id: 3, title: 'Noite' },
];

export const speakers: Array<ISpeaker> = [
  {
    id: 1,
    name: 'Otávio Celidonio',
    company: '',
    position: 'Diretor executivo AgriHub',
    category: 1,
    photo: '/assets/evento/palestrantes/otavio.png',
    description: 'teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste teste',
    activities: [1],
  },
  {
    id: 2,
    name: 'André Bordignon',
    company: '',
    position: 'SebraeRS',
    category: 1,
    photo: '/assets/evento/palestrantes/andre.png',
    activities: [1],
  },
  {
    id: 3,
    name: 'Cibele Bolzan Scherer',
    company: '',
    position: 'Sebrae Campanha e Fronteira Oeste',
    category: 1,
    photo: '/assets/evento/palestrantes/cibele.png',
    activities: [1],
  },
  {
    id: 4,
    name: 'César Oliveira',
    company: '',
    position: '',
    category: 1,
    photo: '/assets/evento/palestrantes/cesar.png',
    activities: [1],
  },
  {
    id: 5,
    name: 'Rogério Melo',
    company: '',
    position: '',
    category: 1,
    photo: '/assets/evento/palestrantes/rogerio.png',
    activities: [1],
  },
  {
    id: 6,
    name: 'Mediadores',
    company: '',
    position: '',
    className: 'separadorPrograma',
    category: 1,
    photo: '',
    activities: [1],
  },
];

export const selectedSpeakers = [1,2,3];

export const getSelectedSpeakers = (): Array<ISpeaker> => {
  const speakersData: Array<ISpeaker> = [];

  selectedSpeakers.map(speakerID => {
    const speakerInfo = speakers.findIndex(sp => sp.id === speakerID);
    if (speakerInfo >= 0) {
      speakersData.push(speakers[speakerInfo]);
    }
  });

  speakersData.sort(function (a, b) {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  return speakersData;
};

export const activities2 = [
  {
    id: 1,
    dateText: '19h',
    photos: ['/assets/evento/palestrantes/otavio.png'],
    text:
      'Palestra Inovação e Agronegócio com Otávio Celidonio, Diretor executivo da AgriHub',
    speakers: [],
  },

  {
    id: 2,
    dateText: '19h30',
    photos: ['/assets/evento/palestrantes/pitches.png'],
    text:
      'Apresentação de pitches com 7 Startups selecionadas e banca avaliadora',
    speakers: [],
  },
  {
    id: 3,
    dateText: '21h - Atração artística',
    photos: [
      '/assets/evento/palestrantes/cesar.png',
      '/assets/evento/palestrantes/rogerio.png',
    ],
    text: 'César Oliveira e Rogério Melo',
    speakers: [],
  },
  {
    id: 4,
    dateText: 'Mediadores',
    photos: [
      '/assets/evento/palestrantes/cesar.png',
      '/assets/evento/palestrantes/rogerio.png',
    ],
    text: 'César Oliveira e Rogério Melo',
    speakers: [5, 6],
  },
];

export const activities = [
  {
    id: 1,
    start: '2020-08-27 13:00:00',
    end: '2020-08-27 19:00:00',
    dateText: '13h',
    period: 1,
    title: 'INTERAÇÃO AO VIVO COM AS STARTUPS',
    subtitle: '',
    description: '',
    content: [],
  },
  {
    id: 2,
    start: '2020-08-27 19:00:00',
    end: '',
    dateText: '19h',
    period: 1,
    title: '19h',
    subtitle: '',
    description: '',
    content: [
      {
        title: '19h - Palestra de abertura - Inovação e Agronegócio',
        speakers: [1],
      },
      {
        title: '19h30 - Apresentação de 7 Startups selecionadas',
        speakers: [],
      },
      {
        title: 'Atração artística',
        speakers: [4, 5],
      },
      {
        title: 'Resultado das Startups Destaque',
        speakers: [],
      },
      {
        title: '',
        speakers: [6, 2, 3],
      },
    ],
  },
  {
    id: 3,
    start: '2020-08-27 19:00:00',
    end: '',
    dateText: '19h30',
    period: 1,
    title: '',
    subtitle: '',
    description: '',
    content: [
      {
        title: 'Apresentação de 7 Startups selecionadas',
        speakers: [],
      },
      {
        title: 'Atração artística',
        speakers: [4, 5],
      },
      {
        title: 'Resultado das Startups Destaque',
        speakers: [],
      },
      {
        title: '',
        speakers: [6, 2, 3],
      },
    ],
  },
  {
    id: 2,
    start: '2020-08-27 19:00:00',
    end: '',
    dateText: '19h',
    period: 1,
    title: 'PALESTRAS, STARTUPS E APRESENTAÇÕES',
    subtitle: '',
    description: '',
    content: [
      {
        title: '19h - Palestra de abertura - Inovação e Agronegócio',
        speakers: [1],
      },
      {
        title: '19h30 - Apresentação de 7 Startups selecionadas',
        speakers: [],
      },
      {
        title: 'Atração artística',
        speakers: [4, 5],
      },
      {
        title: 'Resultado das Startups Destaque',
        speakers: [],
      },
      {
        title: '',
        speakers: [6, 2, 3],
      },
    ],
  },
];

export const program = [
  {
    title: '27/08/2020 - quarta-feira',
    periods: [
      { title: 'TARDE', events: [1], alternative: [] },
      { title: 'NOITE', events: [], alternative: [activities2] },
    ],
  },
];
