import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons';
import { Feira, Expositor, Container } from './styles';
import { ContactIcons } from '../../components/Menu';
import { urlWebsite } from '../../config';
import api from '../../services/api';

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IExpositor {
  id: number;
  background: string;
  capa: string;
  title: string;
  link_name: string;
}

const Expositores: React.FC = () => {
  const [expositors, setExpositors] = useState<Array<IExpositor>>([]);

  useEffect(() => {
    const load = async () => {
      const expositores = await api.get('/expositors-list/1');

      setExpositors(expositores.data.rows);
    };

    load();
  }, []);

  return (
    <Container>
      <div
        id="expositores"
        className="p600 "
        style={{ maxWidth: '400px', marginTop: '30px', textAlign: 'center' }}
      >
        <h2 className="">
          ÁREA DE EXPOSIÇÃO
        </h2>
      </div>



      <Feira>
        <div>
          {expositors.map(expo => {
            return (
              <Expositor
                to={`/expositores/${expo.link_name}`}
                background={`${urlWebsite}/${expo.capa}`}
              >
                <div />
              </Expositor>
            );
          })}
        </div>
      </Feira>
    </Container>
  );
};
export default Expositores;
