import React, { useEffect, useState } from 'react';

import Gallery from '../Gallery';
import { AnimatedDiv, PhotoContainer, Fixed } from './styles';
import { urlWebsite } from '../../config';

interface IPhoto {
  title?: string;
  url: string;
  className?: string;
}

interface IData {
  data: Array<IPhoto>;
  targetOpen?: string;
}

const ImageGallery: React.FC<IData> = ({ data, targetOpen }) => {
  const [currentImage, setCurrentImage] = useState(-1);
  const [dataItems, setDataItems] = useState<Array<IPhoto>>(data);
  const [current, setCurrent] = useState(0);
  const [fixed, setFixed] = useState(false);
  const dataSize = dataItems.length - 1;

  const handleFixed = index => {
    setCurrent(index);
    setCurrentImage(index);
    setFixed(true);
  };

  return (
    <>
      {fixed && (
        <Fixed>
          <div className="imageContent">
            <img src={`${urlWebsite}/${dataItems[current].url}`} alt="Foto" />
          </div>
          <div className="buttons">
            <button
              type="button"
              onClick={() =>
                handleFixed(dataItems[current - 1] ? current - 1 : dataSize)}
            >
              Anterior
            </button>
            <button type="button" onClick={() => setFixed(false)}>
              Fechar
            </button>
            <button
              type="button"
              onClick={() =>
                handleFixed(dataItems[current + 1] ? current + 1 : 0)}
            >
              Próxima
            </button>
          </div>
        </Fixed>
      )}

      <Gallery>
        {data.map((photo, index) => (
          <AnimatedDiv
            key={`${urlWebsite}/${photo.url}`}
            onClick={() => handleFixed(index)}
          >
            <PhotoContainer
              background={photo ? `${urlWebsite}/${photo.url}` : ''}
            >
              <div />
            </PhotoContainer>
          </AnimatedDiv>
        ))}
      </Gallery>
    </>
  );
};
export default ImageGallery;
