const program = { title : 'Hub dos Vales',
    date : '11/09 sábado',
    city: 'Lajeado',
    description : `Avenida Pirai, 97, bairro São Cristóvão, Lajeado/RS<br/>
    Em meio ao ecossistema de tecnologia, o Hub dos Vales conta com  palestras e experiências focadas em inovação e empreendedorismo, o espaço terá atividades próprias e integradas à transmissão do Insight House. O Hub dos Vales será um espaço para criar novas ideias e refletir sobre as tendências e novas formas de criar soluções para trabalhar e empreender. Assim como todos os espaços, o hub estará conectado a todo o evento de forma digital.`,
    day: '11',
    image : '/botao/botao_programacao_hub_vales.png',
    activity : [
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '08:00 às 08:50',
            title : 'Boas Vindas',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '09:00 às 09:50',
            title : 'Transmissão Insight House',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '10:00 às 10:50',
            title : 'Luciano Potter - Palestra Você está de mudança e talvez não saiba',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '11:00 às 11:50',
            title : 'Transmissão Insight House ',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '12:00 às 13:50',
            title : 'Intervalo',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '14:00 às 14:50',
            title : 'Transmissão Insight House ',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '15:00 às 15:50',
            title : 'Transmissão Insight House ',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '16:00 às 16:50',
            title : 'Dado Schneider - O mundo mudou… bem na minha vez',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Palco central',
            room_color: '#ec6607',
            date_text : '17:00 às 17:50',
            title : 'Transmissão Insight House',
            subtitle: '',
            description : '',
            photos : []
        },
        {
            room_name : 'Atrações',
            room_color: 'rgb(0, 0, 100)',
            date_text : '',
            title : `Mostra de Startups<br/>
            Smartbis<br/>
BIMachine<br/>
INSTOR Projetos e Robótica<br/>
Demander<br/>
Podange Smart Wear


            `,
            subtitle: '',
            description : '',
            photos : []
        },
    ]

}

export default program;